import usedIcon from "../../../assets/images/tabler-icon-circle-check-filled.svg";
import givenIcon from "../../../assets/Vectors/given.svg";
import expiredIcon from "../../../assets/images/expired.svg";
import React from "react";

export const PromoInfo = ({ p }) => {
    const isExpired = p.status === 'EXPIRED';
    const isUsed = p.status === 'USED';
    const isGiven = p.status === 'GIVEN';
    const isActive = p.status === 'ACTIVE';

    const promoName = isGiven ? `${p.code.substring(0, 2)}**${p.code.substring(4)}` : p.code;
    const promoStatusClass = isUsed ? 'used' : isGiven ? 'given' : isActive ? 'active' : 'expired';
    const promoStatusText = isUsed ? 'Used' : isGiven ? 'Distributed' : isActive ? 'Active' : 'Expired';
    const promoIcon = isUsed ? usedIcon : isGiven ? givenIcon : isActive ? '' : expiredIcon;

    return (
        <div className='promo-info'>
            <span className='promo-name' style={{ color: isExpired ? '#818898' : '#36394A' }}>
                {promoName}
            </span>
            <span className={`promo-status ${promoStatusClass}`}>
                {promoIcon && <img src={promoIcon} alt={promoStatusText} />}
                {promoStatusText}
            </span>
        </div>
    );
};