import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosPromocodeActiveList } from "../../axios/promocode";
import Loader from "../loader/Loader";
import PrintablePromoCodesTable from "../Table/PrintablePromoCodesTable";
import "./GeneratePromoCode.css";
import ReactDOMServer from 'react-dom/server';



const decodeJWT = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const decodedData = JSON.parse(atob(base64));
  return decodedData;
};

export const GeneratePromoCodePrint = () => {
  const jwt = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [promocodes, setPromocodes] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const decodedJwt = jwt !== null ? decodeJWT(jwt) : "";
  const role = jwt !== null ? decodedJwt?.roles[0] : "";

  const fetchPromocodeList = useCallback(() => {
    setDataLoading(true);
    axiosPromocodeActiveList(0)
        .then((res) => {
          if (res) {
            setPromocodes(res);
            handlePrint(res)
          } else {
            navigate("/login");
          }
        })
        .finally(() => setDataLoading(false));
  }, []);
  const handlePrint = (dataToPrint) => {
    const printableElement = (
        <div>
          <PrintablePromoCodesTable data={dataToPrint} />
        </div>
    );

    const printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

    printWindow.document.write('<html><head><title>' + document.title  + '</title>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(ReactDOMServer.renderToStaticMarkup(printableElement));
    printWindow.document.write('</body></html>');

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    printWindow.print();
    printWindow.close();
  };
  useEffect(() => {
    fetchPromocodeList();
  }, [fetchPromocodeList, role]);

  return (
      <div className="wrapper_print middle">
        {dataLoading ? (
            <div className={'middle'}>
              <Loader />
            </div>
        ) : (
            role === "USER" && <PrintablePromoCodesTable data={promocodes} />
        )}
      </div>
  );
};
