import axios from "axios";
import {promocodeLogin, promocodeList, promocodeGenerate, promoCodeSettings, userInfo} from "../endpoints";
import * as assert from "assert";

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR;

export const axiosLogin = async (username, password) => {
  const encoder = new TextEncoder();
  const strJoin = username + ":" + password;
  const strJoinData = encoder.encode(strJoin);
  const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));
  let config = {
    method: "post",
    headers: {
      Authorization: `Basic ${base64EncodedStr}`,
    },
    url: apiBaseUrl + promocodeLogin,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    alert("Something went wrong: ", err);
    console.log(err);
  }
};

export const axiosPromocodeList = async (page, prefix, pageSize, statuses, dateTo, dateFrom, searchTerm) => {
  console.log("Statuses: ", statuses)
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + promocodeList,
    params: {
      page: page,
      size: pageSize,
      prefix,
      fromDate: dateFrom,
      toDate: dateTo,
      statuses: !statuses ? ['GIVEN', 'EXPIRED', 'USED'].join(',') : statuses,
      code: searchTerm
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const axiosGeneratePromoCodes = async (
  promoCodeAmount,
  toCompany,
  promoTypeId
) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "post",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + promocodeGenerate,
    data: {
      promoCodeAmount,
      toCompany,
      promoTypeId,
    },
  };
  try {
    const response = await axios(config);
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const axiosCheckPromocode = async (promocode) => {
  let config = {
    method: "GET",
    url: apiBaseUrl + `/promocode/check/${promocode}`,
  };
  try {
    const response = await axios(config);
    return response;
  } catch (err) {
    throw err;
  }
};

export const axiosSendPromocode = async (code, plateNumber) => {
  let config = {
    method: "POST",
    url: apiBaseUrl + "/promocode/send",
    data: {
      code,
      plateNumber,
    },
    timeout: 5000,
  };
  try {
    const response = await axios(config);
    return response;
  } catch (err) {
    if (axios.isCancel(err) || err.response?.status === 503) {
      // handle timeout exception and server unavailable code
      throw {
        status: "unavailable",
        response: {
          data: {
            message: "Service is unavailable now, please try again later",
          },
        },
      };
    } else if (
      axios.isCancel(err) ||
      (err.response?.status === 500 &&
        err.response?.error === "Internal Server Error")
    ) {
      throw {
        status: "unavailable",
        response: {
          data: {
            message: "Something goes wrong, please try again later",
          },
        },
      };
    } else if (
      !window.navigator.onLine &&
      !err.response &&
      err.code === "ERR_NETWORK"
    ) {
      throw {
        status: "NO INTERNET",
        response: {
          data: {
            message: "No internet connection",
          },
        },
      };
    } else {
      // handle other types of errors
      throw err;
    }
  }
};

export const axiosGetCompanies = async () => {
  let config = {
    method: "GET",
    url: apiBaseUrl + `/promocode/parkings/companies`,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const axiosAddCompany = async ( companyName) => {
  let config = {
    method: "POST",
    url: apiBaseUrl + `/promocode/parkings/companies/` + companyName,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export const axiosGetPromocodeInfo = async (promocode) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + `/promocode/info/${promocode}`,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const axiosGetPublicPromoCodeInfo = async (promocode) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    url: apiBaseUrl + `/promocode/public/info/${promocode}`,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const axiosGetUserInfo = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + `/promocode/user/info`,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const axiosGetCompaniesInfo = async () => {
  const jwtToken = localStorage.getItem("accessToken");

  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + `/promocode/user/companies/info`,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const axiosChangePromocodeStatus = async (id) => {
  let config = {
    method: "POST",
    url: apiBaseUrl + `/promocode/status`,
    data: {
      id,
      status: "GIVEN",
    },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const axiosPromocodeActiveList = async (page) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + "/promocode/codes/active",
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getPrefixesInfo = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + "/promocode/prefixes/info",
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export const axiosGetPromocodeTypes = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + "/promocode/types",
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const axiosGetPromocodeTypesByCompany= async (companyId) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + "/promocode/byCompany/types/" + companyId,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const axiosGetPromoUserTypes = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + "/promocode/user/types",
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const axiosCreatePromocodeType = async (
  type,
  prefix,
  value,
  limitValue,
  expirationHours,
  description
) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + "/promocode/types/create",
    data: {
      type,
      prefix,
      value: value,
      limitValue: limitValue || 1,
      expirationHours,
      description: " ",
    },
  };
  try {
    const response = await axios(config);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const axiosDeactivatePromocodeType = async (id) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + `/promocode/types/deactivate/${id}`,
    // data: {},
  };
  try {
    const response = await axios(config);
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const getGenerationHistory = async (id) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + "/promocode/byCompany/generation/history/" + id,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export const getGenerationMaxValue = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + promoCodeSettings
  };
  try {
    const response = await axios(config);
    console.log("Resp: ", response.data)
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export const getGenerationUserMaxValue = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    url: apiBaseUrl + userInfo
  };
  try {
    const response = await axios(config);
    console.log("Resp: ", response.data)
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export const deletePromoCodesBundle = async (id, reduceAmount) => {
  const jwtToken = localStorage.getItem("accessToken");
  let config = {
    method: "DELETE",
    url: apiBaseUrl + `/promocode/delete/list/${id}?reduceAmount=${reduceAmount}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};