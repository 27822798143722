import Loader from "../loader/Loader";
import React from "react";

export const Modal = ({clickRef, companyName, setCompanyName, handleAddCompany, setToAdd, companyLoading}) => {
    return (
        <div className="modal-background">
            <div className="modal" ref={clickRef}>

                {
                    companyLoading ? <div className={'col center'}><Loader/></div>
                        :
                        <>
                            <h4>New company</h4>
                            <div className={'col'}>
                                <label>
                                    Name
                                    <input className={'modal-input'} value={companyName} type="text"
                                           onChange={(e) => setCompanyName(e.target.value)}/>
                                </label>
                                <label>
                                    <span>Description <span className={'text-disabled'}>(Optional)</span></span>
                                    <input className={'modal-input'} type="text"/>
                                </label>
                                <div className="buttons">
                                    <button className="cancel" onClick={() => setToAdd(false)}>Back</button>
                                    <button className="add" onClick={handleAddCompany}>Add</button>
                                </div>
                            </div>
                        </>
                }

            </div>
        </div>
    );
}