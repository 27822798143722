import React, {useEffect, useState} from 'react';
import {
    axiosCreatePromocodeType,
    axiosGetPromocodeTypes,
    axiosGetPromocodeTypesByCompany,
    getGenerationMaxValue, getGenerationUserMaxValue
} from "../../axios/promocode";
import Loader from "../loader/Loader";

// Values map should be defined outside the component to avoid re-creation on each render
const valuesMap = {
    '15m': 15,
    '30m': 30,
    '45m': 45,
    '60m': 60,
    '2h': 120,
    '12h': 720,
    '$3': 3,
    '$6': 6,
    '$9': 9
};
const prefixMap = {
    'Hours': 'h',
    'Days': 'd',
    'Month': 'm',
    'Year': 'y'
}

const NewPromo = ({ company, handleSubmit, loading, setLoading, selectedOption }) => {
    const promoType = selectedOption?.promoType || {};
    const [selectedType, setSelectedType] = useState(promoType.type || 'WHITE_LIST');
    const [amount, setAmount] = useState(selectedOption?.promoCodeAmount || 0);
    const [prefix, setPrefix] = useState(promoType.prefix || "");
    const [value, setValue] = useState(promoType.value|| "");
    const [limitValue, setLimitValue] = useState(promoType.limitValue || 1);
    const [expirationHours, setExpirationHours] = useState(calculateAmount(promoType.expirationHours) || 1);
    const [description, setDescription] = useState(promoType.description || "");
    const [usageLimitType, setUsageLimitType] = useState(
        promoType.limitValue ? (promoType.limitValue > 1 ? 'custom' : 'one-time') : 'one-time'
    );
    const [typesList, setTypesList] = useState([]);
    const [userMaxValue, setUserMaxValue] = useState(null)
    const [maxValue, setMaxValue] = useState(null);
    const [expirationType, setExpirationType] = useState(
        selectedOption.promoType ?
            selectedOption?.promoType?.expirationHours < 24
                ? 'Hours'
                : selectedOption?.promoType?.expirationHours >= 24 && selectedOption?.promoType?.expirationHours < 730
                    ? 'Days'
                    : selectedOption?.promoType?.expirationHours >= 730 && selectedOption?.promoType?.expirationHours < 8760
                        ? 'Month'
                        : 'Year'
            : 'Hours'
    );
    const [errors, setErrors] = useState({
        amount: "",
        prefix: "",
        limitValue: "",
        expirationHours: "",
        expirationType: "",
        description: ""
    });
    useEffect(() => {
        if(!promoType.limitValue && usageLimitType === 'custom'){
            setLimitValue(2);
        }
    }, [usageLimitType]);
    useEffect(() => {
        axiosGetPromocodeTypes().then(res => {
            setTypesList(res);
        })
        getGenerationMaxValue().then(res => setMaxValue(res.settingIntValue))
        getGenerationUserMaxValue().then(res => setUserMaxValue(res.userGenerationAmountLeft))
    }, []);
    const validate = () => {
        let tempErrors = {};
        tempErrors.amount = amount > 0 ? "" : "Amount must be greater than 0";
        tempErrors.amount = amount > maxValue ? "Maximum promo codes quantity for this company is " + maxValue : tempErrors.amount;
        tempErrors.amount = amount > userMaxValue ? `Сompany limit exceeded (${userMaxValue})` : tempErrors.amount ;
        tempErrors.limitValue = limitValue > 0 ? '' : 'Limit value must be greater than 0'
        // tempErrors.limitValue = limitValue > 0 ? "" : "Limit must be greater than 0";
        tempErrors.expirationHours = expirationHours > 0 ? "" : "Expiration must be greater than 0";
        tempErrors.expirationType =
            expirationType === 'Month' && expirationHours > 12
                ? 'Expiration must be lower than 12 month (or 1 year)'
                : expirationType === 'Days' && expirationHours > 365
                    ? 'Expiration must be lower than 365 days (or 1 year)'
                    : expirationType === 'Hours' && expirationHours > 8760
                        ? 'Expiration must be lower than 8760 hours (or 1 year)'
                        : "";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const createPromoType = async () => {
        if(validate()){
            setLoading(true);
            let defaultPrefix = selectedType === "WHITE_LIST" ? "15m" : selectedType === "BALANCE" ? "$3" : "";
            let calculatedExpirationHours =
                expirationType === 'Days'
                    ? expirationHours * 24
                    : expirationType === 'Month'
                        ? expirationHours * 24 * 30
                        : expirationType === 'Year'
                            ? expirationHours * 8760
                            : expirationHours
            let finalValue = selectedType === 'WHITE_LIST' && prefix === "" ? 15 : selectedType === 'BALANCE' && prefix === "" ? 3 : value;
            let finalPrefix = prefix === "" ? defaultPrefix : prefix;

            // check if the type already exist
            const existingType = typesList.find(type =>
                type.type === selectedType &&
                type.prefix === finalPrefix &&
                type.value === finalValue &&
                type.limitValue === limitValue &&
                type.expirationHours === calculatedExpirationHours &&
                type.description === description
            );

            if (existingType) {
                handleSubmit(amount, company.name, existingType.id);
            } else {
                try {
                    const res = await axiosCreatePromocodeType(
                        selectedType,
                        finalPrefix,
                        finalValue,
                        limitValue,
                        calculatedExpirationHours,
                        description
                    );
                    handleSubmit(amount, company.name, res.data.id);
                } catch (err) {
                    // handle error if needed
                }
            }
        }
    };

    const typeOptions = {
        WHITE_LIST: ['15m', '30m', '45m', '60m', '2h', '12h'],
        BALANCE: ['$3', '$6', '$9']
    };

    const optionSuffix = selectedType === 'WHITE_LIST' ? 'min' : '$';

    return (
        <div className={'col new-promo'}>

            {loading ? (
                <Loader />
            ) : (
                <div className={'col'} style={{ gap: '20px', marginTop: '45px' }}>
                    <div className={'col'} style={{ gap: '5px' }}>
                        <label className={'text-muted'}>Quantity</label>
                        <input
                            className={errors.amount ? 'error' : 'bx-shadow'}
                            type="number"
                            value={amount}
                            onChange={(e) => {
                                const newAmount = e.target.value;
                                setAmount(newAmount);
                                if (newAmount > 0 && newAmount <= maxValue && newAmount <= userMaxValue) {
                                    setErrors(prevErrors => ({...prevErrors, amount: ""}));
                                }
                            }}
                        />
                        {errors.amount && <p className="promo-err">{errors.amount}</p>}

                    </div>
                    <div className={'col'} style={{ gap: '5px' }}>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <div className="switch-container" style={{flex: '2'}}>
                                <button
                                    className={`switch-btn ${selectedType === 'WHITE_LIST' ? 'active-value' : ''}`}
                                    onClick={() => setSelectedType('WHITE_LIST')}
                                >
                                    Time-limited
                                </button>
                                <button
                                    className={`switch-btn ${selectedType === 'BALANCE' ? 'active-value' : ''}`}
                                    onClick={() => setSelectedType('BALANCE')}
                                >
                                    Balance based
                                </button>
                            </div>
                            <select
                                name="prefix"
                                required
                                value={prefix}
                                className="form-control bx-shadow"
                                onChange={(e) => {
                                    setPrefix(e.target.value);
                                    setValue(valuesMap[e.target.value]);
                                }}
                                style={{flex: '2'}}
                            >
                                {typeOptions[selectedType].map((opt) => (
                                    <option key={opt} value={opt}>{calculateHours(valuesMap[opt], selectedType)}
                                        {valuesMap[opt] >= 60 && selectedType === "WHITE_LIST" ? 'h' : ' ' + optionSuffix}
                                    </option>
                                ))}
                            </select>
                            {/*<select disabled value={optionSuffix} className={'form-control'} style={{ flex: '1' }}>*/}
                            {/*    <option>{optionSuffix}</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div className={'col'} style={{gap: '5px'}}>
                        <label className={'text-loud'}>Usage limit</label>
                        <div className="option" >
                            <label className="usage-container">
                                <input type="checkbox" checked={usageLimitType === 'one-time'} onClick={() => {
                                    setUsageLimitType('one-time')
                                    setLimitValue(1);
                                }} />
                                <span className="checkmark"></span>
                            </label>
                            <div className={'col'}>
                                <label htmlFor="one-time" className={'text-muted'} style={{textAlign: 'start'}}>1 per user</label>
                                <p className={'text-normal'}>Only 1 time per unique user</p>
                            </div>

                        </div>
                        <div className="option">
                            <label className="usage-container">
                                <input type="checkbox" onClick={() => setUsageLimitType('custom')}
                                       checked={usageLimitType === 'custom'}/>
                                <span className="checkmark"></span>
                            </label>

                            <div className={'col'}>
                                <label htmlFor="custom" className={'text-muted'}>
                                    <span
                                        className={usageLimitType === 'no-limit' ? "radio-custom selected" : "radio-custom"}></span>
                                    Custom
                                </label>
                            </div>

                        </div>
                        <div className={'option'}>
                            <label className="usage-container"></label>
                            <input
                                className={errors.limitValue ? 'error' : 'bx-shadow'}
                                type="number"
                                value={usageLimitType !== 'custom' ? 2 : limitValue}
                                disabled={usageLimitType !== 'custom'}
                                onChange={(e) => {
                                    const newLimitValue = e.target.value;
                                    setLimitValue(newLimitValue);
                                    if (newLimitValue > 0) {
                                        setErrors(prevErrors => ({...prevErrors, limitValue: ""}));
                                    }
                                }}
                            />
                            <div className={'col'} style={{marginLeft: '8px'}}>
                                <span className={'text-muted'}>count unique users</span>
                                <span className={'text-normal'}>Only 1 time per unique user</span>
                            </div>
                        </div>
                        {/*<input className={errors.limitValue ? 'error' : ''} value={limitValue}*/}
                        {/*       onChange={(e) => setLimitValue(e.target.value)} placeholder={'Min value = 1'}/>*/}
                        {errors.limitValue && <p className="promo-err">{errors.limitValue}</p>}

                    </div>

                    <div className={'col'} style={{gap: '5px'}}>
                        <label>Expires after</label>
                        <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                            <input
                                className={errors.expirationHours ? 'error' : 'bx-shadow'}
                                value={expirationHours}
                                onChange={(e) => {
                                    const newExpirationHours = e.target.value;
                                    setExpirationHours(newExpirationHours);
                                    if (newExpirationHours > 0) {
                                        setErrors(prevErrors => ({...prevErrors, expirationHours: ""}));
                                    }
                                }}
                                placeholder={'Min value = 1'}
                            />
                            <select value={expirationType} onChange={(e) => {
                                setExpirationType(e.target.value)
                            }} className={'form-control bx-shadow'} style={{width: '80px'}}>
                                {['Hours', 'Days', 'Month', 'Year'].map(opt => (
                                    <option value={opt}>{opt}</option>
                                ))}
                            </select>
                        </div>
                        {errors.expirationType.length > 0 && <p className="promo-err">{errors.expirationType}</p>}
                        {errors.expirationHours.length > 0 && <p className="promo-err">{errors.expirationHours}</p>}
                        <span className={'text-normal'} style={{fontSize: '12px'}}>Expiration time starts after promo code changes to "Given" status</span>

                    </div>
                    {/*<div className={'col'} style={{gap: '5px'}}>*/}
                    {/*    <label>Description</label>*/}
                    {/*    <input  className={errors.description ? 'error' : ''} value={description}*/}
                    {/*           onChange={(e) => setDescription(e.target.value)}/>*/}
                    {/*    {errors.description && <p className="promo-err">{errors.description}</p>}*/}

                    {/*</div>*/}

                    <button className={'generate-promo-codes'} onClick={createPromoType}>
                        Generate
                    </button>
                </div>
            )}
        </div>
    );
};

const calculateHours = (min, selectedType) => {
    return selectedType === "WHITE_LIST" ? min >= 60 ? min / 60 : min : min;
}
const calculateAmount = (amount) => {

    return amount < 24
        ? amount
        : amount >= 24 && amount < 730
            ? Math.floor(amount / 24)
            : amount >= 730 && amount < 8760
                ? (amount / 30) / 24
                : amount / 8760
}
export default NewPromo;
