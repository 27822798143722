import arrowIcon from '../../assets/images/tabler-icon-chevron-down.svg';
import activeChevronIcon from '../../assets/images/chevron-right-active.svg'
import CompanyInfoCol from "./CompanyInfoCol";
import Loader from "../loader/Loader";

const CompaniesList = ({ onSelect, data, selected, companies, managerCompanies }) => {
    const handleClick = company => {
        onSelect(!selected || selected.name !== company.name ? company : null);
    };
    console.log("Data: ", managerCompanies)

    return (
        <div className="item-list" style={{ width: !selected && '100%', height: '80%' }}>
            <div className={'companies-list-header'}>
                <span className={'company-title'}>Company</span>
                <span className={'active-title'}>Active </span>
                <span>Total number</span>
            </div>
            {
                !managerCompanies ?
                    <div className={'col center'}>
                        <Loader />
                    </div>
                    :
                    (
                        <>

                            {data.length !== 0 ?
                                data.map((company, index) => (
                                    <div key={index} onClick={() => handleClick(company)} className='company-item' style={{
                                        backgroundColor: selected ? selected.name === company.name ? '#F6F8FA' : '#FFF' : '#FFF'
                                    }}>
                                        <div className='company-item-left' style={{width: !selected && '50%'}}>
                                            <span style={{width: '180px', maxWidth: '180px', wordWrap: 'break-word',overflowWrap: 'break-word'}}>{company.name}</span>
                                            <div className='company-item-info'>
                                                <span style={{width:'170px'}}>{company.activePromoCodes}</span>
                                                <span style={{width:'170px'}}>{company.promoCodeAmount}</span>
                                            </div>
                                            {selected ? selected.name !== company.name
                                                    ? <img src={arrowIcon} style={{width: '20px', height: '20px'}}/>
                                                    : <img src={activeChevronIcon} style={{width: '20px', height: '20px'}}/>
                                                : ''
                                            }
                                        </div>
                                        {!selected && (
                                            <div className='company-item-right'>

                                                <div className='col center'>
                                                    <img src={arrowIcon} alt="Arrow Icon" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                                :
                                <div className={'col'} style={{padding: '24px'}}>
                                    <span className={'text-loud'} style={{textAlign: 'start'}}>No companies found</span>
                                    <span className={'subdued-text'}>To manage promo codes you need to create companies</span>
                                </div>
                            }
                        </>
                    )
            }

        </div>
    );
}

export default CompaniesList;
