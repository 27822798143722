// features/generatePromoSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Initial state of the generatePromo feature
const initialState = {
    promoGenerated: false,
};

const promoGeneratedSlice = createSlice({
    name: 'promoGenerated',
    initialState,
    reducers: {
        // Action to specifically set generatePromo to true
        togglePromoGenerated: (state) => {
            state.promoGenerated = !state.promoGenerated;
        },
        setPromoGeneratedTrue: (state) => {
            state.promoGenerated = true;

        },
        setPromoGeneratedFalse: (state) => {
            state.promoGenerated = false;
        },
    },
});

// Export the action creators
export const { setPromoGeneratedFalse, setPromoGeneratedTrue, togglePromoGenerated} = promoGeneratedSlice.actions;

// Export the reducer as default
export default promoGeneratedSlice.reducer;
