import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/images/error-img.png";

const PaymentErrorForm = () => {
  let navigate = useNavigate();
    const isUae = localStorage.getItem('isUae') === 'true';

    return (
    <div className="container middle">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={errorImg} style={{ marginLeft: "30px" }} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <span className="infoCar_name">Failure</span>
        <span className="infoCar_address fn-16px">Try again</span>
      </div>

      <div className="PaymentSuccess__parent_div" style={{ height: "200px" }}>
        <button
          className="btn"
          style={{ color: "white" }}
          onClick={() => {
            isUae && localStorage.removeItem('isUae')

              navigate(
              `${isUae ? '/uae' : ''}/parking/${localStorage.getItem(
                "parkingUid"
              )}/${localStorage.getItem("paymentMethod")}`
            );
          }}
        >
          Try again
        </button>
        <button
          className="btn bg-blur"
          style={{ color: "#157FFF", fontWeight: "600" }}
          onClick={() => {
            navigate(`/parking/${localStorage.getItem("parkingUid")}/payment`, {
              state: {
                paymentInfo: {
                  sum: localStorage.getItem("sum"),
                  plateNumber: localStorage.getItem("plateNumber"),
                  currency: localStorage.getItem("currency"),
                },
              },
            });
          }}
        >
          Change payment method
        </button>
        <button
          className="btn bg-blur"
          style={{ color: "red", fontWeight: "600" }}
          onClick={() => {
            navigate(
              `${isUae ? '/uae' : ''}/parking/${localStorage.getItem(
                "parkingUid"
              )}/${localStorage.getItem("paymentMethod")}`
            );
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PaymentErrorForm;
