import React, { useEffect, useState } from "react";
import {ErrorType} from '../../enums/errorTypes';

import "./UaeCarForm.css";
import {
    abuDhabiCodes,
    ajmanCodes,
    dubaiCodes,
    emiratesArray,
    fujarahCodes,
    kuwaitCodes,
    rasAlCodes,
    sharjahCodes,
    ummAlQuwainCodes,
} from "../../static/static";
import { useDispatch, useSelector } from "react-redux";
import {
    setCode,
    setRegion,
    setReverse,
} from "../../features/uaeCarFormSlice";
import {useIsFirstRender} from "../../hooks/useIsFirstRender";

const codesMap = {
    "Dubai": dubaiCodes,
    "Abu Dhabi": abuDhabiCodes,
    "Sharjah": sharjahCodes,
    "Ajman": ajmanCodes,
    "Umm Al Quwain": ummAlQuwainCodes,
    "Ras Al Khaima": rasAlCodes,
    "Fujairah": fujarahCodes,
    "Kuwait": kuwaitCodes,
};
const noRegion = {
    "Other region": 1,
    "Qatar": 1,
    "Bahrain": 1,
};
const noCodes = {
    "Oman": 1,
    "Saudi Arabia": 1,
};
const isReverse = {
    "Oman": true,
    "Saudi Arabia": true,
};

const UaeCarForm = ({
                        plateNumber,
                        error,
                        handlePlateNumber,
                    }) => {
    const dispatch = useDispatch();
    const { region, code, reverse } = useSelector((state) => state.uaeCarForm);
    const [isCodeFocused, setIsCodeFocused] = useState(noCodes[region] !== 1);
    const [isPlateNumberFocused, setIsPlateNumberFocused] = useState(false);
    const isFirstRender = useIsFirstRender();
    useEffect(() => {
        if (!isFirstRender) {
            localStorage.setItem('region', region);
            localStorage.setItem('code', code);
            localStorage.setItem('isReversed', reverse ? 'true' : 'false');
        }
    }, [region, code, reverse, isFirstRender]);

    useEffect(() => {
        const storedRegion = localStorage.getItem("region");
        const storedCode = localStorage.getItem("code");
        const isReversed = localStorage.getItem("isReversed") === "true";

        if (storedRegion) {
            dispatch(setRegion(storedRegion));
        }
        if (storedCode) {
            dispatch(setCode(storedCode));
        }
        dispatch(setReverse(isReversed));
    }, [dispatch]);
    const handleRegionChange = (e) => {
        const newRegion = e.target.value;
        dispatch(setRegion(newRegion));

        dispatch(setReverse(isReverse[newRegion] ?? false));
        setIsCodeFocused(noCodes[newRegion] !== 1);
    };
    const handleCodeChange = (e) => {
        dispatch(setCode(e.target.value));
    };
    const handleCodeFocus = () => {
        setIsCodeFocused(true);
    };

    const handleCodeBlur = () => {
        setIsCodeFocused(false);
    };

    const handlePlateNumberFocus = () => {
        setIsPlateNumberFocused(true);
    };

    const handlePlateNumberBlur = () => {
        setIsPlateNumberFocused(false);
    };

    return (
        <div
            className={'uae-enter-car-form'}
        >
            <div className={"select-region-container"}>
                <span>Select your vehicle region / emirate</span>
                <select
                    className={"select-region"}
                    onChange={handleRegionChange}
                    value={region}
                >
                    {emiratesArray.map((reg) => (
                        <option value={reg} key={reg}>
                            {reg}
                        </option>
                    ))}
                </select>
            </div>
            {noRegion[region] !== 1 ? (
                <div
                    className={`select-code-container ${
                        (reverse || localStorage.getItem("isReversed") === "true") && "reverse"
                    }`}
                >
                    <div className="input-wrapper plate-code">
                        {noCodes[region] !== 1 ? (
                            <select
                                id="vehicle-number-select"
                                onChange={handleCodeChange}
                                value={code}
                                style={{ width: "100%", paddingBottom: "3px" }}
                            >
                                {codesMap[region].map((code) => (
                                    <option value={code} key={code}>
                                        {code === "" ? "No plate" : code}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                placeholder={isCodeFocused ? "" : "Plate code"}
                                style={{ padding: "18px 16px" }}
                                type="text"
                                onChange={handleCodeChange}
                                onBlur={handleCodeBlur}
                                onFocus={handleCodeFocus}
                                value={code}
                                // className={`${error.type === ErrorType.USER_BASED  && "red"}`}
                            />
                        )}
                        {isCodeFocused && (
                            <label htmlFor="input-field" style={{ top: "13px" }}>
                                Plate code
                            </label>
                        )}
                    </div>
                    <div className="input-wrapper uae-plate-number">
                        <input
                            type="text"
                            placeholder={isPlateNumberFocused ? "" : "Plate number"}
                            className={`${error.type === ErrorType.USER_BASED  && "red"}`}
                            onBlur={handlePlateNumberBlur}
                            onFocus={handlePlateNumberFocus}
                            value={plateNumber}
                            onChange={handlePlateNumber}
                            style={{border: error.type === ErrorType.USER_BASED ? '1px solid red' : ''}}
                        />
                        {isPlateNumberFocused && (
                            <label htmlFor="input-field" style={{ top: "13px" }}>
                                Plate number
                            </label>
                        )}
                    </div>
                    {error.type === ErrorType.USER_BASED && error.msg}
                </div>
            ) : (
                <div className="input-wrapper uae-plate-number">
                    <input
                        type="text"
                        value={plateNumber}
                        onBlur={handlePlateNumberBlur}
                        onFocus={handlePlateNumberFocus}
                        placeholder={isPlateNumberFocused ? "" : "Plate number"}
                        onChange={handlePlateNumber}
                        style={{ padding: "18px 16px" }}
                        className={`${error.type === ErrorType.USER_BASED  && "red"}`}
                    />
                    {error.type === ErrorType.USER_BASED && error.msg}
                    {isPlateNumberFocused && (
                        <label htmlFor="input-field" style={{ top: "13px" }}>
                            Plate number
                        </label>
                    )}
                </div>
            )}
        </div>
    );
};

export default UaeCarForm;
