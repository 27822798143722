import React from 'react';
import SelectedCode from './SelectedCode';
import closeGrayIcon from '../../../assets/Vectors/closeGray.svg';
import {PromoInfo} from "./PromoInfo";
import {formatDateToYYYYMMDDHHmm} from "../../../utils/helpers";

function HistoryItem({ p, selectedCode, setSelectedCode, lastAddedCode, handleMessage}) {
    return (
        <div className={`col ${lastAddedCode?.code === p.code ? 'new-promocode' : ''}`} style={{background: lastAddedCode?.code === p.code ? '#F4FBFF' : ''}} key={p.code}>
            <div className={`opened-promocode`}
                 onClick={() => selectedCode !== p.code && setSelectedCode(p.code)}
                 style={{ borderBottom: selectedCode === p.code ? 'none' : '' }}>
                <div className='opened-promocode-left'>
                    <PromoInfo p={{...p, code: p.code.startsWith(p.promoType.prefix + '-') ? p.code.slice(p.promoType.prefix.length + 1) : p.code}} />

                    {p.plateNumber ? (
                        <div style={{ display: 'flex' }}>
                            <div className='promo-plate-number'>
                                {p.plateNumber}
                            </div>
                        </div>
                    ) : <div style={{ height: selectedCode !== p.code ? '22px' : '0', width: '1px' }}></div>}
                </div>
                <div className='used-promocode-right'>
                    {
                        selectedCode !== p.code ? (
                            <>
                                <span className='promocode-type'>{p.promoType.prefix}</span>
                                <span className='lastUpdatedAt'>{formatDateToYYYYMMDDHHmm(p.modifiedAt)}</span>
                            </>
                        ) : (
                            <img style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                 src={closeGrayIcon || ''} onClick={() => setSelectedCode(null)}
                                 alt={'close icon'} />
                        )
                    }
                </div>
            </div>
            {
                selectedCode && p.code === selectedCode && (
                    <SelectedCode
                        selectedCode={p}
                        promoType={p.promoType}
                        plateNumber={p.plateNumber}
                        status={p.status}
                        modifiedAt={p.modifiedAt}
                        handleMessage={handleMessage}
                        formatDateToYYYYMMDDHHmm={formatDateToYYYYMMDDHHmm}
                    />
                )
            }
        </div>
    );
}

export default HistoryItem;
