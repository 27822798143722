import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { axiosPortico } from "../../axios/axiosRequest";
import Loader from "../loader/Loader";
import logo from "../../assets/images/mainLogo.png";

const GlobalPayments = ({ paymentInfo }) => {
  const [hps, setHps] = useState(null);
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState(
    "Something went wrong, please try again later!"
  );
  const [loading, setLoading] = useState(false);
  console.log("Payment info: ", paymentInfo);
  const [cardValidation, setCardValidation] = useState({
    cardNumber: false,
    cardExpiration: false,
    cardCvv: false,
    email: false,
  });
  const [errorMessages, setErrorMessages] = useState({
    cardNumber: "",
    cardExpiration: "",
    cardCvv: "",
    email: "",
  });
  const [count, setCount] = useState(0);
  const [event, setEvent] = useState();
  useEffect(() => {
    // Create a new `HPS` object with the necessary configuration
    // eslint-disable-next-line no-undef
    const newHps = new Heartland.HPS({
      publicKey: "pkapi_cert_0F59KJlBFNmvZTLvMM",
      type: "iframe",
      // Configure the iframe fields to tell the library where
      // the iframe should be inserted into the DOM and some
      // basic options
      fields: {
        cardNumber: {
          target: "iframesCardNumber",
          placeholder: "Card number",
        },
        cardExpiration: {
          target: "iframesCardExpiration",
          placeholder: "Card expiration",
        },
        cardCvv: {
          target: "iframesCardCvv",
          placeholder: "Card CVV",
        },
        submit: {
          value: `Pay ${paymentInfo.sum
            .toLocaleString("en")
            .replace(/,/g, " ")} ${paymentInfo.currency}`,
          target: "iframesSubmit",
        },
      },
      style: {
        input: {
          background: "#fff",
          border: "1px solid",
          "border-color": "#bbb3b9 #c7c1c6 #c7c1c6",
          "box-sizing": "border-box",
          "font-family": "Inter !important",
          "font-size": "16px !important",
          "line-height": "19px !important",
          "letter-spacing": "0.012em !important",
          margin: "0 .5em 0 0",
          "max-width": "100%",
          outline: "0",
          padding: "0.5278em",
          "vertical-align": "baseline",
          height: "50px",
          width: "100% !important",
        },
        "#heartland-field": {
          "font-family": "sans-serif",
          "box-sizing": "border-box",
          display: "block",
          height: "54px",
          padding: "6px 12px",
          "font-size": "14px",
          "line-height": "1.42857143",
          color: "#555",
          "background-color": "#fff",
          border: "none",
          "border-radius": "0px",
          "-webkit-box-shadow": "inset 0 1px 1px rgba(0,0,0,.075)",
          "box-shadow": "inset 0 1px 1px rgba(0,0,0,.075)",
          "-webkit-transition":
            "border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s",
          "-o-transition":
            "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
          transition:
            "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
          width: "100%",
        },
        "#heartland-field[name=submit]": {
          "align-items": "center",
          "background-color": "#1c74da",
          border: "none",
          "border-radius": "18px",
          color: "#fff",
          cursor: "pointer",
          display: "flex",
          "flex-direction": "row",
          "font-size": "14px",
          "font-weight": "550",
          height: "56px",
          "justify-content": "center",
          "letter-spacing": ".5px",
          width: "100%",
        },
        "#heartland-field[name=submit]:focus": {
          color: "#ffffff",
          "background-color": "#258851",
          outline: "none",
        },
        // '#heartland-field[name=submit]:hover': {
        //     'background-color':'red'
        // },
        "#heartland-field-wrapper #heartland-field:focus": {
          // 'border':'1px solid #3989e3',
          // 'outline':'none',
          // 'box-shadow':'none',
          // 'height':'50px'
        },
        "heartland-field-wrapper #heartland-field": {
          height: "56px",
          border: "none",
        },

        "input[type=submit]": {
          "box-sizing": "border-box",
          display: "inline-block",
          padding: "6px 12px",

          border: "1px solid transparent",
          "border-radius": "4px",
          color: "#fff",
          "font-family": "Inter !important",
          "font-size": "16px !important",
          "line-height": "19px !important",
          "letter-spacing": "0.012em !important",
          "font-weight": "600 !important",
          "background-color": `${errMsg ? "#9DCAF8" : "#1c74da"}`,
          "border-color": "#2e6da4",
        },
        "#heartland-field[placeholder]": {
          "letter-spacing": "3px",
        },
        "#heartland-frame-cardNumber": {
          width: "30px",
        },
        "#heartland-field[name=cardCvv]": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right",
          "background-size": "63px 40px",
        },
        "input#heartland-field[name=cardNumber]": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right",
          "background-size": "55px 35px",
        },
        "#heartland-field.invalid.card-type-visa": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right",
          "background-size": "83px 88px",
          "background-position-y": "-44px",
        },
        "#heartland-field.valid.card-type-visa": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top",
          "background-size": "82px 86px",
        },
        "#heartland-field.invalid.card-type-discover": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right",
          "background-size": "85px 90px",
          "background-position-y": "-44px",
        },
        "#heartland-field.valid.card-type-discover": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right",
          "background-size": "85px 90px",
          "background-position-y": "1px",
        },
        "#heartland-field.invalid.card-type-amex": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right",
          "background-size": "50px 90px",
          "background-position-y": "-44px",
        },
        "#heartland-field.valid.card-type-amex": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top",
          "background-size": "50px 90px",
        },
        "#heartland-field.invalid.card-type-mastercard": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right",
          "background-size": "62px 105px",
          "background-position-y": "-52px",
        },
        "#heartland-field.valid.card-type-mastercard": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right",
          "background-size": "62px 105px",
          "background-position-y": "-1px",
        },
        "#heartland-field.invalid.card-type-jcb": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right",
          "background-size": "55px 94px",
          "background-position-y": "-44px",
        },
        "#heartland-field.valid.card-type-jcb": {
          background:
            "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top",
          "background-size": "55px 94px",
          "background-position-y": "2px",
        },
        "input#heartland-field[name=cardNumber]::-ms-clear": {
          display: "none",
        },
      },

      // Callback when a token is received from the service
      onTokenSuccess: function (resp) {
        console.log("Token success: ", resp);
        setLoading(true);
        console.log("Here is a single-use token: " + resp.token_value);
        console.log("Sending email: ", localStorage.getItem("email"));
        axiosPortico(
          paymentInfo.plateNumber,
          paymentInfo.sum,
          resp.token_value,
          paymentInfo.parkingUid,
          localStorage.getItem("email")
        ).then((resp) => {
          if (resp.status === 200) {
            setLoading(false);
            navigate("/payment-success");
          } else {
            setLoading(false);
            navigate("/payment-error");
          }
        });
      },
      // Callback when an error is received from the service
      onTokenError: function (resp) {
        setLoading(false);
        if (!cardValidation.cardNumber) {
          setErrorMessages((prevState) => ({
            ...prevState,
            cardNumber: "Сard number is incorrect",
          }));
        }
        if (!cardValidation.cardExpiration) {
          setErrorMessages((prevState) => ({
            ...prevState,
            cardExpiration: "Fill the card expiration date field",
          }));
        }
        if (!cardValidation.cardCvv) {
          setErrorMessages((prevState) => ({
            ...prevState,
            cardCvv: "Fill the card CVV field",
          }));
        }
        if (!email) {
          setErrorMessages((prevState) => ({
            ...prevState,
            email: "Fill the email field",
          }));
        }
      },
      // Callback when an event is fired within an iFrame
      onEvent: function (ev) {
        console.log("On event: ", ev);
        const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (ev.source === "cardNumber" && ev.classes[0] === "valid") {
          setCardValidation((prevState) => ({
            ...prevState,
            cardNumber: true,
          }));
        } else if (
          ev.source === "cardExpiration" &&
          ev.classes[0] === "valid"
        ) {
          setCardValidation((prevState) => ({
            ...prevState,
            cardExpiration: true,
          }));
        } else if (ev.source === "cardCvv" && ev.classes[0] === "valid") {
          setCardValidation((prevState) => ({
            ...prevState,
            cardCvv: true,
          }));
        } else if (regexPattern.test(email)) {
          setCardValidation((prevState) => ({
            ...prevState,
            email: true,
          }));
        }

        setEvent(ev);
      },
    });

    setHps(newHps);

    return () => {
      newHps.dispose();
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("email", email);
  }, [email]);
  // const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    console.log("Count: ", count);
  }, [count]);
  function validateEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setShowError(!emailRegex.test(email));
  }
  return (
    <>
      <div className="container middle">
        <div className={"welcome_div"}>
          <div>
            <img src={logo} />
          </div>
          <span>Parking information</span>
        </div>
        {loading ? (
          <div className={'middle'}>
            <Loader />
          </div>
        ) : (
          <div
            className="check-form"
            style={{
              height: `${
                Object.values(errorMessages).some((value) => value !== "")
                  ? "550px"
                  : "450px"
              }`,
            }}
          >
            <form id="iframes" action="" method="GET">
              <div id="ss-banner" />

              <div className="form-row">
                <div
                  id="iframesCardNumber"
                  className={errorMessages.cardNumber && "check"}
                />
              </div>
              {errorMessages.cardNumber && (
                <span className="error_msg">{errorMessages.cardNumber}</span>
              )}

              <div className="form-row">
                <div
                  id="iframesCardExpiration"
                  className={errorMessages.cardExpiration && "check"}
                />
              </div>
              {errorMessages.cardExpiration && (
                <span className="error_msg">
                  {errorMessages.cardExpiration}
                </span>
              )}

              <div className="form-row">
                <div
                  id="iframesCardCvv"
                  className={errorMessages.cardCvv && "check"}
                />
              </div>
              {errorMessages.cardCvv && (
                <span className="error_msg">{errorMessages.cardCvv}</span>
              )}

              <div
                className="email_input_container"
                style={{ marginBottom: "5px" }}
              >
                <input
                  type="text"
                  className={showError ? "email_field error" : "email_field"}
                  placeholder="example@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validateEmail}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {errorMessages.email && (
                  <span className="error_msg">{errorMessages.email}</span>
                )}

                <span className="email_description">
                  We will send a receipt to this email
                </span>
              </div>

              <br />
              <div id="iframesSubmit" style={{ marginTop: "30px" }} />
              <style>
                {`
            #iframesSubmit:first-child {
              display: none;
            }
          `}
              </style>
            </form>
          </div>
        )}
      </div>
    </>
  );
};
export default GlobalPayments;
