export const emiratesArray = [
    "Dubai",
    "Abu Dhabi",
    "Sharjah",
    "Ajman",
    "Umm Al Quwain",
    "Ras Al Khaima",
    "Fujairah",
    "Saudi Arabia",
    "Oman",
    "Qatar",
    "Bahrain",
    "Kuwait",
    "Other region"
];
export const regionCodes = {
    "Dubai": "DXB",
    "Abu Dhabi": "AUH",
    "Sharjah": "SHJ",
    "Ajman": "AJM",
    "Umm Al Quwain": "UAQ",
    "Ras Al Khaima": "RAK",
    "Fujairah": "FUJ",
    "Other region": ""
}

export const abuDhabiCodes = [
    1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 50,
];
export const ajmanCodes = ["A", "B", "C", "D", "E", "H"];
export const dubaiCodes = [
    "",
    "A",
    "AA",
    "B",
    "BB",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];
export const sharjahCodes = [1, 2, 3, 4, ""];
export const ummAlQuwainCodes = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "X",
];
export const rasAlCodes = ["A", "B", "C", "D", "I", "K", "M", "N", "S", "V", "Y"];
export const fujarahCodes = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "K",
    "M",
    "P",
    "R",
    "S",
    "T",
];
export const qatarCodes = ["No plate"];
export const bahrainCodes = ["No plate"];
export const kuwaitCodes = [
    "",
    1,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    2,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    3,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    4,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    5,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    6,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    7,
    70,
    71,
    72,
    73,
    74,
    75,
    76,
    77,
    78,
    79,
    8,
    80,
    81,
    82,
    83,
    84,
    85,
    86,
    87,
    88,
    89,
    9,
];

export const currencySymbols = {
    AED: 'AED ',
    AFN: '؋',
    ALL: 'L',
    AMD: '֏',
    ANG: 'ƒ',
    AOA: 'Kz',
    ARS: '$',
    AUD: 'A$',
    AWG: 'ƒ',
    AZN: '₼',
    BAM: 'KM',
    BBD: 'Bds$',
    BDT: '৳',
    BGN: 'лв',
    BHD: 'ب.د',
    BIF: 'FBu',
    BMD: 'BD$',
    BND: 'B$',
    BOB: 'Bs.',
    BRL: 'R$',
    BSD: 'B$',
    BTN: 'Nu.',
    BWP: 'P',
    BYN: 'Br',
    BZD: 'BZ$',
    CAD: 'CA$',
    CDF: 'FC',
    CHF: 'CHF',
    CLP: 'CLP$',
    CNY: 'CN¥',
    COP: 'COL$',
    CRC: '₡',
    CUP: 'CUP$',
    CVE: '$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: 'RD$',
    DZD: 'د.ج',
    EGP: 'ج.م',
    ERN: 'Nfk',
    ETB: 'Br',
    EUR: '€',
    FJD: 'FJ$',
    FKP: '£',
    FOK: 'ƒ',
    GBP: '£',
    GEL: '₾',
    GGP: '£',
    GHS: 'GH₵',
    GIP: '£',
    GMD: 'D',
    GNF: 'FG',
    GTQ: 'Q',
    GYD: 'GY$',
    HKD: 'HK$',
    HNL: 'L',
    HRK: 'kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    IMP: '£',
    INR: '₹',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'kr',
    JEP: '£',
    JMD: 'J$',
    JOD: 'د.ا',
    JPY: '¥',
    KES: 'KSh',
    KGS: 'лв',
    KHR: '៛',
    KID: 'CI$',
    KRW: '₩',
    KWD: 'د.ك',
    KYD: 'KY$',
    KZT: '₸',
    LAK: '₭',
    LBP: 'ل.ل',
    LKR: 'Rs',
    LRD: 'LR$',
    LSL: 'M',
    LYD: 'ل.د',
    MAD: 'د.م.',
    MDL: 'L',
    MGA: 'Ar',
    MKD: 'ден',
    MMK: 'K',
    MNT: '₮',
    MOP: 'MOP$',
    MRU: 'UM',
    MUR: 'Rs',
    MVR: 'Rf',
    MWK: 'MK',
    MXN: 'Mex$',
    MYR: 'RM',
    MZN: 'MT',
    NAD: 'N$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: 'Rs',
    NZD: 'NZ$',
    OMR: 'ر.ع.',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: 'Rs',
    PLN: 'zł',
    PYG: '₲',
    QAR: '﷼',
    RON: 'lei',
    RSD: 'din',
    RUB: '₽',
    RWF: 'RF',
    SAR: 'SR',
    SBD: 'SI$',
    SCR: 'SR',
    SDG: 'ج.س.',
    SEK: 'kr',
    SGD: 'S$',
    SHP: '£',
    SLL: 'Le',
    SOS: 'S',
    SRD: 'SR$',
    STN: 'Db',
    SVC: '$',
    SYP: 'ل.س',
    SZL: 'E',
    THB: '฿',
    TJS: 'SM',
    TMT: 'T',
    TND: 'د.ت',
    TOP: 'T$',
    TRY: '₺',
    TTD: 'TT$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    USD: '$',
    UYU: '$U',
    UZS: 'лв',
    VES: 'Bs.',
    VND: '₫',
    VUV: 'VT',
    WST: 'WS$',
    XAF: 'FCFA',
    XCD: 'EC$',
    XDR: 'SDR',
    XOF: 'CFA',
    XPF: 'CFPF',
    YER: '﷼',
    ZAR: 'R',
    ZMW: 'ZK',
    ZWL: '$',
};


