export const getBillingInfo = "/payment/check";
export const getBillingToken = "/user/ext_login";
export const porticoPayment = "/usa/portico/charge";

export const stripePaymentProd = "/stripe/checkout?source=croatia-golubica";
export const stripePaymentDev = "/stripe/checkout?source=croatia-parqour-test";
export const m2payPayment = "/m2pay/pay";

export const promocodeLogin = "/promocode/auth/login";
export const promocodeList = "/promocode/codes";
export const promocodeGenerate = "/promocode/generate";
export const promoCodeSettings = "/promocode/settings/generation/max-value"
export const userInfo = "/promocode/user/info"

export const abonementTypes = '/abonement/types/'
export const abonementCreate = '/abonement/create'
export const getParking = '/public/parking/uid/'
