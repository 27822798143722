import TrashSvg from "../../assets/images/TrashSvg";
import { useDispatch } from "react-redux";
import { setGeneratePromoTrue } from "../../features/generatePromoSlice";
import copyIcon from '../../assets/images/copy.svg';
import editIcon from '../../assets/images/edit.svg';

const getItemStyle = (isDeletable) => ({
    color: isDeletable ? "#DF1C41" : "#A4ACB9",
});

const renderDropdownItem = (className, onClick, children, itemColor) => (
    <div className={`dropdown-item ${className}`} style={{ color: itemColor }} onClick={onClick}>
        {children}
    </div>
);

const DropdownMenu = ({ selectedOption, type, setToDelete, clickRef, setToReduce }) => {
    const dispatch = useDispatch();

    if (selectedOption.generationId !== type.generationId) {
        return null;
    }

    const isDeletable = type.activePromoCodes === type.promoCodeAmount;

    return (
        <div className="same-generate" ref={clickRef}>
            {renderDropdownItem(
                '',
                () => dispatch(setGeneratePromoTrue()),
                <>
                    <img src={copyIcon} alt="Copy Icon"/> Generate with same settings
                </>,
                "#36394A"
            )}

            {renderDropdownItem(
                '',
                () => {
                    setToDelete(null);
                    setToReduce(type);
                },
                <>
                    <img src={editIcon || ""} alt="Edit Icon"/> Reduce the quantity
                </>,
                "#36394A"
            )}

            {renderDropdownItem(
                'text-disabled',
                () => {
                    setToReduce(null);
                    isDeletable && setToDelete(type);
                },
                <>
                    <TrashSvg color={getItemStyle(isDeletable).color}/> Delete
                </>,
                getItemStyle(isDeletable).color
            )}
        </div>
    );
};

export default DropdownMenu;
