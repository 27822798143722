import React from 'react';
import {calculateMinutes} from "../../../utils/helpers";

const SelectedCode = ({ selectedCode,promoType, plateNumber, status, modifiedAt, handleMessage, formatDateToYYYYMMDDHHmm }) => {
    console.log("Promo type: ", promoType)
    return (
        <div className='col' style={{padding: '16px 24px', borderBottom: '1px solid #ECEFF3', gap: '5px'}}>
            <div style={{display: 'flex'}}>
                <span className='subdued-text key'>Value</span>
                <span className='text-loud' style={{fontSize: '14px'}}>{`${promoType.type === 'BALANCE' ? '$' : ''}${promoType.type !== 'BALANCE' ? calculateMinutes(promoType.value) : promoType.value}`}</span>
            </div>
            <div style={{display: 'flex'}}>
                <span className='subdued-text key'>Usage limit</span>
                <span className='text-loud' style={{fontSize: '14px'}}>{promoType.limitValue}</span>
            </div>

            <div style={{display: 'flex'}}>
                <span className='subdued-text key'>Plate number</span>
                {
                    plateNumber && <span className='promo-plate-number'>{plateNumber}</span>
                }

            </div>

            {
                selectedCode.distributedAt ?
                (
                    <div style={{display: 'flex'}}>
                        <span className='subdued-text key'>Distributed at:</span>
                        <span className='text-loud' style={{fontSize: '14px'}}>{formatDateToYYYYMMDDHHmm(selectedCode.distributedAt)}</span>
                    </div>
                ) :
                selectedCode.modifiedAt &&
                (
                    <div style={{display: 'flex'}}>
                        <span className='subdued-text key'>Modified at:</span>
                        <span className='text-loud'
                              style={{fontSize: '14px'}}>{formatDateToYYYYMMDDHHmm(selectedCode.modifiedAt)}</span>
                    </div>
                )
            }
            {
                selectedCode.status === 'GIVEN' && selectedCode.expiresAt &&
                (
                    <div style={{display: 'flex'}}>
                        <span className='subdued-text key'>Valid until:</span>
                        <span className='text-loud' style={{fontSize: '14px'}}>{formatDateToYYYYMMDDHHmm(selectedCode.expiresAt)}</span>
                    </div>
                )
            }
            {
                selectedCode.status === 'EXPIRED' && selectedCode.modifiedAt &&
                (
                    <div style={{display: 'flex'}}>
                        <span className='subdued-text key'>Expired: </span>
                        <span className='text-loud' style={{fontSize: '14px'}}>{formatDateToYYYYMMDDHHmm(selectedCode.modifiedAt)}</span>
                    </div>
                )
            }
            {
                selectedCode.status === 'USED' && selectedCode.usedAt &&
                (
                    <div style={{display: 'flex'}}>
                        <span className='subdued-text key'>Used at: </span>
                        <span className='text-loud' style={{fontSize: '14px'}}>{formatDateToYYYYMMDDHHmm(selectedCode.usedAt)}</span>
                    </div>
                )
            }

        </div>
    );
};

export default SelectedCode;
