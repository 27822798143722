import React, {useEffect} from 'react';
import emptyCheckbox from '../../../assets/images/empty-checkbox.svg'
import checkedIcon from '../../../assets/images/Checkbox.svg'
import filterIcon from '../../../assets/images/tabler-icon-adjustments-horizontal.svg'

const Filter = ({
                    clickRef, filterIsOpen, setFilterIsOpen, dateFrom, dateTo, handleDateChange,
                    status, handleFilterByStatus, handleResetAll, setSelectedType, setChangeStatus
                }) => {
    const showCircle = status.length > 0 || dateFrom !== '' || dateTo !== '';

    const circleContent = showCircle ? (
        <div className={'circle'}>
            {status.length + ((dateFrom !== '' || dateTo !== '') ? 1 : 0)}
        </div>
    ) : null;
    return (
        <div className="filter-container" ref={clickRef}>
            <button className='filters' onClick={() => setFilterIsOpen(!filterIsOpen)}>
                Filters {circleContent} <img src={filterIcon} alt="Toggle Filters"/>
            </button>
            {filterIsOpen && (
                <div className="filter-dropdown">
                    <label className='text-normal'>Date From</label>
                    <input style={{marginLeft: '12px'}} type="date" value={dateFrom} name="dateFrom" onChange={handleDateChange}/>
                    <label className='text-normal'>Date To</label>
                    <input style={{marginLeft: '12px'}} type="date" value={dateTo} name="dateTo" onChange={handleDateChange}/>
                    <label className='text-normal'>Status</label>
                    {['Given', 'Expired', 'Used'].map((statusValue) => (
                        <label key={statusValue} className={'text-muted'}>
                            {statusValue === 'Given' ? 'Distributed' : statusValue}
                            <label className="custom-radio">
                                <input
                                    type="checkbox"
                                    name="status"
                                    style={{display: 'none'}}
                                    value={statusValue.toUpperCase()}
                                    onChange={handleFilterByStatus}
                                />
                                {status.includes(statusValue.toUpperCase()) ? <img src={checkedIcon} alt="Checked"/> : <img src={emptyCheckbox} alt="Unchecked"/>}
                            </label>
                        </label>
                    ))}
                    <div className="buttons-group">
                        <span className='reset-btn' onClick={handleResetAll}>Reset All</span>
                        <button className='generate-promo-codes' style={{padding: '4px 10px', marginTop: '0'}} onClick={() => {
                            setSelectedType(false);
                            setChangeStatus(prev => !prev);
                        }}>Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Filter;
