const TrashSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M3.33337 5.83333H16.6667M4.16671 5.83333L5.00004 15.8333C5.00004 16.2754 5.17564 16.6993 5.4882 17.0118C5.80076 17.3244 6.22468 17.5 6.66671 17.5H13.3334C13.7754 17.5 14.1993 17.3244 14.5119 17.0118C14.8244 16.6993 15 16.2754 15 15.8333L15.8334 5.83333M7.50004 5.83333V3.33333C7.50004 3.11232 7.58784 2.90036 7.74412 2.74408C7.9004 2.5878 8.11236 2.5 8.33337 2.5H11.6667C11.8877 2.5 12.0997 2.5878 12.256 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333M8.33337 10L11.6667 13.3333M11.6667 10L8.33337 13.3333"
                stroke={color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default TrashSvg;