const CompanyInfoCol = ({ label, value, generatedHistory = false }) => {
    const activeCondition = label === 'Active' && value < 1;
    const colClasses = `col company-info-col ${activeCondition ? 'company-info-col-active' : ''} ${generatedHistory ? 'company-info-col-history' : ''}`;

    return (
        <div className={colClasses}>
            {generatedHistory ? (
                <>
                    <span className='subdued-text'>{label}</span>
                    <span className={'text-loud'} style={{textAlign: 'unset'}}>{value}</span>
                </>
            ) : (
                <span>{value}</span>
            )}
        </div>
    );
};

export default CompanyInfoCol;
