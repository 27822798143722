import React, {useEffect} from "react";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import CompaniesList from "./CompaniesList";
import CompanyDetail from "./CompanyDetail";
import './Companies.css'
const Companies = ({ data, handleSubmit, loading, setLoading,companies, fetchPrefixesInfo, companyIsAdded, managerCompanies, fetchCompanies }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [mergedArray, setMergedArray] = useState([])
    const [firstRender, setFirstRender] = useState(true);
    const mergeArrays = (data, companies) => {

        return companies.map(company => {
            const companyData = data.find(d => d.company === company.name);

            if (companyData) {
                return {
                    ...company,
                    ...companyData
                };
            }

            return {
                ...company,
                activePromoCodes: 0,
                expiredPromoCodes: 0,
                givenPromoCodes: 0,
                promoCodeAmount: 0,
                usedPromoCodes: 0
            };
        });
    };
    useEffect(() => {

        if(companies.length > 0){
            const combinedArr = mergeArrays(data, companies);
            if(firstRender){
                setSelectedItem(combinedArr[0]);
                setFirstRender(false);
            }
            if(companyIsAdded){
                setSelectedItem(combinedArr.find(el => el.name === companyIsAdded));
            }
            setMergedArray(combinedArr)
        }

    }, [data, companies]);

    return (
        <div className={'companies-wrapper'}>
            <CompaniesList onSelect={setSelectedItem}
                           data={data.length === 0 && companies.length === 0 ? [] : mergedArray}
                           selected={selectedItem}
                           companies={companies}
                           managerCompanies={managerCompanies}
            />
            {
                selectedItem && (
                    <CSSTransition
                        in={!!selectedItem}
                        timeout={300}
                        classNames="item-detail-animation"
                        unmountOnExit
                    >
                        <CompanyDetail company={selectedItem} onSelect={setSelectedItem} fetchCompanies={fetchCompanies} handleSubmit={handleSubmit} loading={loading} setLoading={setLoading} companies={companies} fetchPrefixesInfo={fetchPrefixesInfo} />
                    </CSSTransition>
                )
            }

        </div>
    );
};

export default Companies;
