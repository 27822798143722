import '../Companies.css'
import {calculateHours, calculateMinutes} from "../../../utils/helpers";
import {useState} from "react";
import {deletePromoCodesBundle} from "../../../axios/promocode";
import LoadingSpinner from "../../loader/Spinner";
const DeleteBundlesModal = ({type, setToDelete, fetchHistory, fetchCompanies, toReduce, setToReduce}) => {
    const [qty, setQty] = useState(type.activePromoCodes || 0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    console.log("Type: ", type)
    const handleDelete = async () => {
        setLoading(true);
        await deletePromoCodesBundle(type.generationId, qty).then(r => {}).finally(() => setLoading(false));
        fetchHistory();
        fetchCompanies();
        setToDelete(null);
        setToReduce(null);
    }

    return (
        <div className={'delete-bundle-modal'}>
            <span className={'delete-bundle-modal-title'}>
                {
                    toReduce
                        ? "Decrease an amount of promo codes?"
                            : "Are you sure you want to delete?"
                }
            </span>
            {
                toReduce && <span className={'delete-bundle-modal-title'} style={{fontSize: '16px'}}>You cannot delete all promo code, but you may reduce quantity</span>
            }
            <div className={'bundle-type-info'}>
                <span className={'text-content'} style={{fontSize: '18px'}}>
                    {`${type.promoType.type === 'BALANCE' ? '$' : ''}${type.promoType.type !== 'BALANCE' ? calculateMinutes(type.promoType.value) : type.promoType.value}`}
                </span>
                <CompanyInfoCol value={`${type.promoType.limitValue} users`} label={"Limit"} />
                <CompanyInfoCol value={`${calculateHours(type.promoType.expirationHours)}`} label={"Expires after"}  />
                <CompanyInfoCol value={`${type.promoType.type === 'BALANCE' ? '$' : ''}${type.promoType.type !== 'BALANCE' ? calculateMinutes(type.promoType.value) : type.promoType.value}`} label={"Value"} />
            </div>
            {
                toReduce &&
                <div className={'bundle-type-info'}>
                    <CompanyInfoCol value={`${type.activePromoCodes}`} label={"Active"} fontSize={'14px'} />
                    <CompanyInfoCol value={`${type.usedPromoCodes}`} label={"Used"} fontSize={'14px'} />
                    <CompanyInfoCol value={`${type.givenPromoCodes}`} label={"Distributed"} fontSize={'14px'} />
                    <CompanyInfoCol value={`${type.expiredPromoCodes}`} label={"Expired"} fontSize={'14px'} />
                    <CompanyInfoCol value={`${type.promoCodeAmount}`} label={"Total"} fontSize={'14px'} />
                </div>
            }
            {
                toReduce && (
                    <div className={'col'} style={{width: '100%', gap: '6px'}}>
                        <span>Decrease quantity by</span>
                        <input
                            className={'delete-bundle-modal-input'}
                            type='number'
                            value={qty}
                            onChange={(e) => {
                                const inputValue = parseInt(e.target.value, 10);
                                if (inputValue < 1 || inputValue > type.activePromoCodes) {
                                    setError('Quantity must be between 1 and ' + type.activePromoCodes);
                                    setQty(inputValue);
                                } else {
                                    setError('');
                                    setQty(inputValue);
                                }
                            }}
                        />
                        {error && <span className='error_msg'>{error}</span>}
                        <span className={'text-normal'} style={{fontSize: '12px'}}>The total quantity can only be reduced by the amount of active promo codes</span>
                    </div>
                )
            }
            <div style={{display: 'flex', justifyContent: 'end', width: '100%'}}>
                <div className={'row'} style={{gap: '10px'}}>
                    <button className={'cancel-button'} onClick={() => {
                        setToDelete(null)
                        setToReduce(null)
                    }}>Cancel</button>
                    <button className={'dlt-button'} disabled={error} onClick={handleDelete}>
                        {
                            loading ? <LoadingSpinner /> : toReduce ? `Reduce down to ${type.promoCodeAmount - qty || 0} pcs`
                                : "Delete"
                        }
                    </button>
                </div>
            </div>

        </div>
    )
}
const CompanyInfoCol = ({ label, value, fontSize = '12px'}) => {

    return (
        <div className={'col'}>
            <span className='subdued-text' style={{fontSize: fontSize}}>{label}</span>
            <span className={`${fontSize !== '12px' ? 'text-loud' : 'text-muted'}`} style={{textAlign: 'unset', fontSize: '14px'}}>{value}</span>
        </div>
    );
};


export default DeleteBundlesModal;