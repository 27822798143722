import {useNavigate, useSearchParams} from "react-router-dom";
import successImg from "../../assets/images/success-img.png";
import {formatCheckInDate} from "../../utils/helpers";
import React from "react";

const PaymentSuccessForm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isUae = localStorage.getItem('isUae') === 'true';
  const parkingUid = searchParams.get('parkingUid');
  const subscription = localStorage.getItem('subscription') === 'true';
  let inDateValue = localStorage.getItem('inDate');
  let inDate;
  let duration;
  let finalDate;
  if(isUae && inDateValue){
      inDate = inDateValue ? new Date(inDateValue) : null;
      duration = Math.ceil((new Date() - inDate) / (1000 * 60));
      let currDur = Math.floor(duration / 60);
      duration = Math.ceil(duration / 60);
      let exitDate = new Date(inDate.getTime() + duration * 60 * 60000);
      if (currDur > 5) {
          finalDate = new Date(exitDate.getTime() + 24 * 60 * 60000);
      } else if (exitDate.getMinutes() >= 50) {
          finalDate = new Date(exitDate.getTime() + (parkingUid === "129" ? 15 : 10) * 60000);
      } else {
          finalDate = exitDate;
      }
  }



    return (
        <div className="container middle">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={successImg} alt="" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <span className="infoCar_name">Success!</span>
            <span className="infoCar_address fn-16px" style={{ marginTop: "10px" }}>
              Payment was successful!
            </span>
          </div>

          <div
            className="PaymentSuccess__parent_div"
            style={{ height: "80px", marginTop: "30px", justifyContent: "center" }}
          >
              {
                  isUae && inDateValue && inDateValue !== 'null' && !subscription ?
                      (
                          <div className={'col'}>
                              <span style={{textAlign: 'center'}} className={'text-normal'}>Free exit before</span>
                              <span style={{textAlign: 'center'}}>
                                {
                                    formatCheckInDate(
                                       finalDate
                                    )
                                }
                              </span>
                          </div>
                      ) :
                      (
                          <button
                              className="btn"
                              style={{color: "white"}}
                              onClick={() => {
                                  isUae && localStorage.removeItem('isUae')

                                  navigate(
                                      `${isUae ? '/uae' : ''}/parking/${localStorage.getItem(
                                          "parkingUid"
                                      )}/${localStorage.getItem("paymentMethod")}`
                                  );
                              }}
                          >
                              Continue
                          </button>
                      )
              }

              {/*<button className='btn bg-blur' style={{color: '#157FFF', fontWeight: '600'}}>Parking ticket</button>*/}
          </div>
        </div>
    );
};

export default PaymentSuccessForm;
