export const ParkingStatus = Object.freeze({
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    FREE_MINUTES_ON_ENTRANCE: 'FREE_MINUTES_ON_ENTRANCE',
    FREE_MINUTES_AFTER_PAYMENT: 'FREE_MINUTES_AFTER_PAYMENT',
    INCORRECT_TRANSACTION_ID: 'INCORRECT_TRANSACTION_ID',
    INCORRECT_SUM: 'INCORRECT_SUM',
    PROVIDER_NOT_CONNECTED_TO_PARKING: 'PROVIDER_NOT_CONNECTED_TO_PARKING',
    PAYMENT_WAS_NOT_PRESENT_BUT_RESCHEDULED: 'PAYMENT_WAS_NOT_PRESENT_BUT_RESCHEDULED',
    TRANSACTION_ALREADY_PRESENT: 'TRANSACTION_ALREADY_PRESENT',
    PARKING_UNAVAILABLE: 'PARKING_UNAVAILABLE',
});

