import React from "react";
import { useLocation, useParams } from "react-router-dom";

import GlobalPayments from "../GlobalPayments/GlopalPayments";

function PaymentForm() {
  const location = useLocation();
  const params = useParams();
  let paymentInfo = location.state.paymentInfo;
  paymentInfo["parkingUid"] = params.parkingUid;
  console.log(paymentInfo);

  return (
    <>
      <GlobalPayments paymentInfo={paymentInfo} />
    </>
  );
}
export default PaymentForm;
