import React, {useEffect, useCallback, useState, useRef, useContext} from "react";
import { useNavigate } from "react-router-dom";
import {isMobile, isTablet} from "react-device-detect";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from 'react-redux';
import {setGeneratePromoFalse, setGeneratePromoTrue} from '../../features/generatePromoSlice';
import {
  axiosGeneratePromoCodes,
  axiosGetCompanies,
  axiosGetUserInfo,
  axiosGetCompaniesInfo,
  axiosPromocodeList,
  axiosChangePromocodeStatus,
  axiosGetPromocodeTypes,
  axiosGetPromoUserTypes,
  axiosCheckPromocode, getPrefixesInfo, axiosPromocodeActiveList, axiosAddCompany, axiosDeactivatePromocodeType,
} from "../../axios/promocode";
import Loader from "../../components/loader/Loader";
import Companies from "../../components/Companies/Companies";
import OpenPromoCode from "../../components/PromoCodes/OpenPromoCode";

import "./PromoCode.css";

import closeIcon from '../../assets/images/closeIcon.svg'
import historyIcon from '../../assets/images/historyIcon.svg'
import arrowLeft from '../../assets/images/arrow_left.svg'
import emptyBox from '../../assets/images/empty-box.svg'
import searchIcon from "../../assets/images/search.svg";
import ticketIcon from '../../assets/images/ticket.svg'
import logoutIcon from '../../assets/images/logout.svg'
import printerIcon from '../../assets/images/printer.svg';
import {setPromoGeneratedTrue, togglePromoGenerated} from "../../features/promoGeneratedSlice";
import {calculateHours, calculateMinutes, formatDateToYYYYMMDDHHmm, formatToApiDate} from "../../utils/helpers";
import {NoData} from "../../components/PromoCodes/NoData";
import {Modal} from "../../components/PromoCodes/Modal";
import {QRSection} from "../../components/PromoCodes/QR_Section";
import PromoListHistory from "../../components/PromoCodes/PromoList/PromoListHistory";
const decodeJWT = (token) => {
  const base64Url = token?.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  const decodedData = JSON?.parse(atob(base64));
  return decodedData;
};

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const directUrl = process.env.REACT_APP_DIRECT;

export const PromoCode = () => {
  const jwt = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [firstCodeByPrefix, setFirstCodeByPrefix] = useState(null);
  const [selectedType, setSelectedType] = useState(false)
  const [promocodes, setPromocodes] = useState([]);
  const [companyIsAdded, setCompanyIsAdded] = useState(null);
  const [addCompanyLoading, setCompanyLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [activeDataLoading, setActiveDataLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [companies, setCompanies] = useState([]);
  const [info, setInfo] = useState();
  const [qty, setQty] = useState(0);
  const [managerCompanies, setManagerCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const [filteredActualCompanies, setFilteredActualCompanies] = useState({});
  const [promocodesLoading, setPromocodesLoading] = useState([])
  const [alertMessage, setAlertMessage] = useState("");
  const [typesList, setTypesList] = useState([]);
  const [selectedPrefix, setSelectedPrefix] = useState("");
  const [toAdd, setToAdd] = useState(false)
  const [isTwoTypes, setIsTwoTypes] = useState(false)
  const [activePromocodes, setActivePromocodes] = useState(false)
  const [selectedCode, setSelectedCode] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [status, setStatus] = useState([])
  const [changeStatus, setChangeStatus] = useState(false)
  const [isSentToGiven, setIsSentToGiven] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [lastAddedCode, setLastAddedCode] = useState(null);
  const [historyList, setHistoryList] = useState(false);
  const [toastInfo, setToastInfo] = useState({
    state: false,
    msg: ''
  })

  const inputRef = useRef(null);
  const clickRef = useRef(null);
  const firstUpdate = useRef(true);
  const decodedJwt = jwt !== null ? decodeJWT(jwt) : "";
  const role = jwt !== null ? decodedJwt?.roles[0] : "";
  const [selectedPageName, setSelectedPageName] = useState((role === 'ADMIN' || role === 'MANAGER') ? 'GENERATE' : 'LIST')


  const dispatch = useDispatch();
  useEffect(() => {
    function handleClickOutside(event) {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        setFilterIsOpen(false);
        setToAdd(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickRef]);
  const handlePrevPage = () => {
    if(page > 1){
      setPage(Number(page) - 1);
      setCurrentPage(Number(currentPage) - 1);
    }

  };
  let stompClient;

  const connect = async () => {
    try {
      const socket = new SockJS(directUrl + '/promocode/ws');
      stompClient = Stomp.over(socket);
      let res = await stompClient.connect({});

      return res ? "success" : "error";
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
      return error;
    }
  };

  const disconnect = async () => {
    if (stompClient) {
      try {
        await stompClient.disconnect(() => {
          console.log('Disconnected from WebSocket');
        }, {
          HttpStatusCode: "1000",

        });

      } catch (error) {
        console.error('Error disconnecting from WebSocket:', error);
      }
    }
  };

  const subscribeToPromoCodeUpdates = async () => {
    try {
      await stompClient.subscribe('/topic/promoCodeStatus', (message) => {
        const promo = JSON.parse(message.body);
        console.log("Event from socket: ", promo);
        let pressedButton = localStorage.getItem('pressedButton');
        if(!pressedButton && promo && (promo.id === Number(localStorage.getItem('firstPromoId')))){
          console.log("Promo from socket: ", promo);
          setActivePromocodes(activePromocodes.filter(
              item =>
                  item.id !== promo.id
          ))
          setIsSentToGiven(selectedType);
          setPromoCode(promo.code);
          const newPromoCode = {
            id: promo.id,
            code: promo.code,
            status: 'GIVEN',
            modifiedAt: new Date(),
            promoType: selectedType,
            expiresAt: new Date(new Date().getTime() + selectedType.limitValue * 60 * 60 * 1000)
          };
          setLastAddedCode(newPromoCode);
          setPromocodes([newPromoCode, ...promocodes]);

          setTypesList(typesList.map(
              item =>
                  item.id === selectedType.id ? {
                    ...item, activeCount: item.activeCount - 1
                  } : item
          ))
        }
        localStorage.removeItem('pressedButton');
        return JSON.parse(message.body);
      });
    } catch (error) {
      console.error('Error subscribing to promo code updates:', error);
      return error;
    }
  };

  async function connectAndSubscribeToPromoCode() {
    try {
      setLoading(true)
      const result = await connect();
      if (result === "success") {
        console.log("Connected to WebSocket server successfully.");
        setTimeout(async () => {
          const promoCode = await subscribeToPromoCodeUpdates();
          setLoading(false)
        }, 3000)

        return "cool";
      } else {
        console.error("Error connecting to WebSocket server:", result);
      }
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    if(selectedType){
      setQty(selectedType.activeCount);
      connectAndSubscribeToPromoCode().then(res => console.log("res: ", res)).catch(err => console.log("err: ", err));
    }

    return () => {
      disconnect();
    }
  }, [selectedType]);
  const handleNextPage = () => {
    if(page < totalPages){
      setPage(Number(page) + 1);
      setCurrentPage(Number(currentPage) + 1);
    }
  };
  const handleCurrentPageChange = (e) => {
    setCurrentPage(e.target.value);
    inputRef.current.style.width = `${Math.max(8, e.target.value.length * 8)}px`;
  };

  const fetchPromocodeList = useCallback((searchTerm = '') => {

    setPromocodesLoading(true);
    axiosPromocodeList(page - 1, selectedPrefix, pageSize,
        status.length > 1 ? status.join(',') : status[0],
        dateTo, dateFrom, searchTerm)
        .then((res) => {
          if (res) {
            setTotalPages(res?.maxNumberOfPages);
            setPromocodes(res?.promoCodes)
          } else {
            navigate("/login");
          }
        })
        .finally(() =>
            setPromocodesLoading(false)
        );
  }, [page, pageSize, selectedPrefix, changeStatus]);

  const fetchPrefixesInfo = useCallback(async () => {
    const res = await getPrefixesInfo();
    if (res) {
      return res;
    }
  }, []);
  const fetchPromocodeTypesList = useCallback(async () => {
    setDataLoading(true);
    try {
      let res;

      if (selectedPageName === 'GENERATE') {
        res = await axiosGetPromocodeTypes();
      } else {
        res = await axiosGetPromoUserTypes();

      }

      if (res) {
        if (selectedPageName !== 'GENERATE') {
          let mergedList = res;
          mergedList.sort((a, b) => {
            if (a.type === 'WHITE_LIST' && b.type !== 'WHITE_LIST') {
              return -1;
            } else if (b.type === 'WHITE_LIST' && a.type !== 'WHITE_LIST') {
              return 1;
            } else {
              return a.value - b.value;
            }
          });
          let activeMergedList = mergedList.filter(item => item.active === true);
          activeMergedList = mergeActiveMergetList(activeMergedList);
          setTypesList(activeMergedList);
        } else {
          setTypesList(res);
        }
        setDataLoading(false)
      } else {
        navigate("/login");
      }
    } catch (e) {

      navigate("/login");
    }
  }, [selectedPageName]);
  const fetchActiveList = async () => {
    try {
      setActiveDataLoading(true);
      const res = await axiosPromocodeActiveList(0);
      if (res) {
        setActivePromocodes(res);
        return res;
      } else {
        navigate("/login");
      }
    } catch (error) {
      // Handle any errors here
    } finally {
      setActiveDataLoading(false);
    }
  };


  const fetchCompanies = () => {
    axiosGetCompanies()
      .then((res) => {
        setCompanies(res);
        setFilteredActualCompanies(res)
        // setCompany(res[0]?.name);
      })
      .catch((err) => console.log(err))
  };

  const fetchCompanyInfo = () => {
    axiosGetCompaniesInfo()
      .then((res) => {
        setManagerCompanies(res);
        setFilteredCompanies(res)
      })
      .catch((err) => console.log(err));
  };



  const fetchUserInfo = () => {
    axiosGetUserInfo()
      .then((res) => {
        setInfo(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (selectedPageName === 'GENERATE') {
      fetchCompanyInfo();
      fetchCompanies();
    } else {
      fetchActiveList();
      fetchUserInfo();
      // fetchPromocodeList();
    }
    fetchPromocodeTypesList();
  }, [role, fetchPromocodeTypesList, selectedPageName]);
  useEffect(() => {
    fetchPromocodeList();

  }, [page, pageSize, changeStatus])
  const   handleSubmit = (amount, company, promoTypeId) => {
    // setLoading(true);
    dispatch(togglePromoGenerated());
    axiosGeneratePromoCodes(amount, company, promoTypeId)
      .then((res) => {
        dispatch(setGeneratePromoFalse());
        fetchCompanyInfo();
        setToastInfo({
          state: true,
          msg: `${amount} promo codes for ${company} generated`
        })
        // toast.success(`${amount} promo codes generated`, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      })
      .catch((err) => {
        console.log("err: ", err)
        if (err.response.data) {
          setToastInfo({
            state: true,
            msg: `${err.response.data}`
          })
          // toast.error(err.response.data.error, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        } else {
          setToastInfo({
            state: true,
            msg: `Something went wrong`
          })
          // toast.error(`Something went wrong`, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
      })
      .finally(() => {
        setLoading(false)

      });
  };

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        setAlertMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [alertMessage]);

  const onChangeStatus = (code) => {
    console.log('Code: ', code)
    setLoading(true)
    localStorage.setItem('pressedButton', 'true')
    axiosChangePromocodeStatus(code[1]).then((res) => {
      if (res) {

        setActivePromocodes(prevState => {
          const newState = prevState.filter(promo => promo.id !== code[1]);
          return newState;
        });

        setQty(
            qty > 0
                    ? qty - 1
                      : qty
        )
        setIsSentToGiven(selectedType);
        fetchUserInfo();
        const newPromoCode = {
          id: code[1],
          code: code[0],
          status: 'GIVEN',
          modifiedAt: new Date(),
          promoType: selectedType,
          expiresAt: new Date(new Date().getTime() + selectedType.limitValue * 60 * 60 * 1000)
        };
        setLastAddedCode(newPromoCode);
        setPromocodes([newPromoCode, ...promocodes]);
        setLoading(false);
        setTypesList(typesList.map(
            item =>
                item.id === selectedType.id ? {
                  ...item, activeCount: item.activeCount - 1
                } : item
        ))
      }
    });
  };

  const handleDeactivateType = (id) => {
    setTypesList(typesList.filter(item => item.id !== id));
    axiosDeactivatePromocodeType(id).then(res => {
      console.log("Successfully deleted: ", res)
    }).catch(err => alert(err))
  }

  const handleAddCompany = () => {
    setCompanyLoading(true)
    axiosAddCompany(companyName).then((res) => {
      if(res){
        toast.success(`${res}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setToAdd(false)
        setCompanyIsAdded(companyName);
        fetchCompanies()
      }
    }).catch(err => {
      toast.error(`Something went wrong. Please Try again later`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }).finally(() => setCompanyLoading(false))
  }

  const handleCompanyInputChange = (value) => {
    setSearchCompany(value);
    if(value !== ''){
      let actCompanies = companies.filter(company => company.name.toUpperCase().startsWith(value.toUpperCase()));
      let comp = managerCompanies.filter(company => company.company.toUpperCase().startsWith(value.toUpperCase()));
      console.log("Act: ", actCompanies)
      console.log("Comp: ", comp )
      setFilteredActualCompanies(actCompanies)
      setFilteredCompanies(comp)

    }else{
      setFilteredActualCompanies(companies)
      setFilteredCompanies(managerCompanies)
    }
  }
  const handleFilterByStatus = (e) => {
    setStatus(prevState => {
      if (prevState.includes(e.target.value)) {
        return prevState.filter(status => status !== e.target.value);
      } else {
        return [...prevState, e.target.value];
      }
    })
  }
  const handleResetAll = () => {
    setSelectedType(false);
    setStatus([]);
    setDateFrom('')
    setDateTo(new Date().toISOString().split('T')[0])
    setSelectedType(false);
    setChangeStatus(!changeStatus)
  }
  useEffect(() => {
    if (activePromocodes && selectedType.id) {
      console.log("Length after: ", activePromocodes.length)

      let matchingPromoCode = activePromocodes.find(promo => selectedType.id.includes(promo.promoType.id));
      if (matchingPromoCode) {
        localStorage.setItem('firstPromoId', matchingPromoCode.id);
        setFirstCodeByPrefix([matchingPromoCode.code, matchingPromoCode.id]);
      } else {
        setFirstCodeByPrefix("");
      }
    }

  }, [activePromocodes, selectedType]);
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const formattedDate = new Date(value).toISOString().split('T')[0];

    if (name === "dateFrom") {
      if (new Date(formattedDate) > new Date(dateTo)) {

        setDateFrom(dateTo);
      } else {
        setDateFrom(formattedDate);
        if(dateTo === ''){
          setDateTo(formattedDate)
        }
      }
    } else if (name === "dateTo") {

      if (new Date(dateFrom) > new Date(formattedDate)) {

        setDateTo(dateFrom);
      } else {
        if(dateFrom === ''){
          setDateFrom(formattedDate)
        }
        setDateTo(formattedDate);
      }
    }
  };
  const mobStyle = {
    width: '100%',
    background: 'none',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center'
  };

  return (
    <div className={'main-wrapper'}>
      {
        toAdd && (
            <Modal clickRef={clickRef} companyName={companyName} setCompanyName={setCompanyName} setToAdd={setToAdd} handleAddCompany={handleAddCompany} companyLoading={addCompanyLoading} />
          )
      }
      {
          isMobile && !selectedType &&
            <div className={'promocode-header'} style={{justifyContent: 'flex-end'}}>
              <button
                  onClick={() => {
                    localStorage.removeItem('accessToken')
                    navigate('/login')
                  }}
                  className={'logout-btn'}
              >
                <span className={'text-normal'}>Log out</span>
                <img src={logoutIcon || ''}/>
              </button>
            </div>
      }
      <div className={'promocode-header'}>
        {
            selectedType && !historyList ? (
                <div className={'back-button-wrapper'}>
                  <div className={'back-button'} onClick={() => {
                    setLastAddedCode(null)
                    setSelectedType(false)
                  }}>
                    <img src={arrowLeft || ''} alt={'back button'}/>
                    {!isMobile && <span className={'text-normal'} style={{fontSize: '18px'}}>Back</span>}
                  </div>
                  <span className={'text-loud selectedType_title'} >
                    {`${calculateMinutes(selectedType.value)} / ${selectedType.limitValue} user${selectedType.limitValue > 1 ? 's' : ''} / expires after ${calculateHours(selectedType.expirationHours)}`}
                </span>
                </div>
            ) : (
                <div className={'promocode-header-title'}>
                  {
                    historyList ?
                        <div className={'back-button'} onClick={() => setHistoryList(false)}>
                          <img src={arrowLeft || ''} alt={'back button'}/>
                          <span className={'text-normal'} style={{fontSize: '18px'}}>Back</span>
                        </div>
                        :
                        <>
                          <img src={ticketIcon || ''} style={{width: '20px', height: '20px'}} alt={'label icon'}/>
                          <span className={'text-loud'}>Promo Codes</span>
                        </>
                  }

                </div>
            )
        }

        {
          isMobile ? (

              <div style={{flex: '1', display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                {historyList && <span className={'text-content'} style={{fontSize: '16px'}}>Promo codes history</span>}
                <img onClick={() => setHistoryList(true)} style={{cursor: 'pointer'}} src={historyIcon || ''}
                     alt={'show promo codes history'}/>
              </div>

          ) : (
              <button
                  onClick={() => {
                    localStorage.removeItem('accessToken')
                    navigate('/login')
                  }}
                  className={'logout-btn'}
              >
                <span className={'text-normal'}>Log out</span>
                <img src={logoutIcon || ''}/>
              </button>
          )
        }

      </div>



      <div className="wrapper"
           style={{border: 'none', width: '100%', height: '100%', background: selectedPageName === 'GENERATE' ? '#FFF' : '#F8FAFB'}}>
        {/*{role === "USER" && <UserInfoCards info={info} />}*/}
        {selectedPageName === 'GENERATE' && (
            <div className="manager-search" style={{padding: '16px 24px'}}>
              <label >
                <img src={searchIcon || ''} className='magnifier' style={{left: '35px'}} />
                <input placeholder={'Company Name'} value={searchCompany} className='search-input' onChange={(e) => handleCompanyInputChange(e.target.value)} />
                <button className={'clear-btn'} onClick={() => {
                  setSearchCompany('')
                  handleCompanyInputChange('')
                }}>Clear</button>
              </label>
            </div>
        )}
        {alertMessage && (
            <p className="message message_success">{alertMessage}</p>
        )}
        <div className="promocode-list">
          {isMobile && !historyList && !selectedType &&
              (<div style={{display: 'flex', padding: '10px', alignItems: 'center'}}>
                    <div className={'print-promocode'} style={{flex: '8'}}
                         onClick={() => navigate('/generate/promo-code/print')}>Print Active Promo
                      Codes <img src={printerIcon || ''}/>
                    </div>
                  </div>
              )
          }
          {
              toastInfo.state &&
              <div className={'custom-toast'}>
                {toastInfo.msg}
                <img src={closeIcon || ''}
                     onClick={() =>
                         setToastInfo({state: false, msg: ''})
                    }
                     style={{cursor: 'pointer'}}
                     alt={'close it'}
                />
              </div>
          }
          {activeDataLoading || dataLoading ? (
              <div style={{margin: "0 auto"}}>
                <Loader/>
              </div>
          ) : historyList ?
              <PromoListHistory
                  typesList={typesList} clickRef={clickRef} filterIsOpen={filterIsOpen} setFilterIsOpen={setFilterIsOpen}
                  dateFrom={dateFrom} dateTo={dateTo} handleDateChange={handleDateChange} status={status}
                  handleFilterByStatus={handleFilterByStatus} handleResetAll={handleResetAll} setSelectedType={setSelectedType}
                  setChangeStatus={setChangeStatus} promocodesLoading={promocodesLoading} promocodes={promocodes} selectedCode={selectedCode}
                  setSelectedCode={setSelectedCode} formatDateToYYYYMMDDHHmm={formatDateToYYYYMMDDHHmm} handleMessage={handleMessage}
                  pageSize={pageSize} setPage={setPage} setCurrentPage={setCurrentPage} setPageSize={setPageSize} page={page} totalPages={totalPages}
                  currentPage={currentPage} handlePrevPage={handlePrevPage} handleNextPage={handleNextPage} handleCurrentPageChange={handleCurrentPageChange}
                  inputRef={inputRef} lastAddedCode={lastAddedCode} fetchPromocodeList={fetchPromocodeList}
              /> : (
              (selectedPageName === 'LIST' && (
                  <div className='promo-wrapper'>
                    <div className={`promo-left-side ${selectedType && (!isMobile || isTablet) ? 'opened' : ''}`} style={(isMobile && selectedType) ? mobStyle : {}}>

                            {
                              isMobile && !isTablet ?
                                  !selectedType ?
                                      <div className={'mobile_cards_list'}>
                                        {!typesList ?
                                            <div className={'col center'} style={{height: '100%'}}>
                                              <NoData src={emptyBox} classNames={['']} title={'No promo codes yet'}
                                                      desc={'Please contact the admin to order more'}/>
                                            </div> :
                                            typesList.map(type => <OpenPromoCode
                                                setSelectedPromoPrefix={setSelectedType}
                                                type={type}
                                                handleDeactivateClick={handleDeactivateType}
                                                typesList={typesList}
                                            />)}
                                      </div>

                                      :
                                      <div className={'opened-promocode-wrapper'}>
                                        <QRSection selectedType={selectedType} firstCodeByPrefix={firstCodeByPrefix}
                                                   info={info} isSentToGiven={isSentToGiven} qty={qty}
                                                   setIsSentToGiven={setIsSentToGiven} onChangeStatus={onChangeStatus}
                                                   setSelectedType={setSelectedType}/>
                                      </div>
                              :
                                  <div className="flipper">
                                    <div className="front" style={{gridTemplateColumns: typesList?.length <= 4 ? 'repeat(4, 1fr)' : '', gridGap: typesList?.length <= 4 ? '10px' : ''}}>
                                      {

                                        !typesList ?
                                            <div className={'col center'} style={{height: '100%'}}>
                                              <NoData src={emptyBox} classNames={['']} title={'No promo codes yet'}
                                                      desc={'Please contact the admin to order more'}/>
                                            </div> :
                                            typesList.map(type =>
                                                  <OpenPromoCode
                                                    setSelectedPromoPrefix={setSelectedType} type={type}
                                                    handleDeactivateClick={handleDeactivateType}
                                                    typesList={typesList}
                                                  />)
                                      }
                                    </div>
                                    <div className={`back ${!selectedType ? 'hide' : ''}`}>
                                      {
                                        loading ?
                                            <div className={'col center'}><Loader/></div>
                                            :
                                            <>
                                              <QRSection selectedType={selectedType}
                                                         firstCodeByPrefix={firstCodeByPrefix}
                                                         info={info} isSentToGiven={isSentToGiven} qty={qty}
                                                         setIsSentToGiven={setIsSentToGiven}
                                                         onChangeStatus={onChangeStatus}
                                                         setSelectedType={setSelectedType}/>

                                            </>
                                      }
                                    </div>
                                  </div>

                            }
                            {(!selectedType && typesList && !isMobile) &&
                                <div className={'print-promocode'}
                                     onClick={() => navigate('/generate/promo-code/print')}>Print Active Promo
                                  Codes <img src={printerIcon}/></div>
                            }
                          </div>
                    {!isMobile && !isTablet &&
                      <PromoListHistory
                          typesList={typesList} clickRef={clickRef} filterIsOpen={filterIsOpen} setFilterIsOpen={setFilterIsOpen}
                          dateFrom={dateFrom} dateTo={dateTo} handleDateChange={handleDateChange} status={status}
                          handleFilterByStatus={handleFilterByStatus} handleResetAll={handleResetAll} setSelectedType={setSelectedType}
                          setChangeStatus={setChangeStatus} promocodesLoading={promocodesLoading} promocodes={promocodes} selectedCode={selectedCode}
                          setSelectedCode={setSelectedCode} formatDateToYYYYMMDDHHmm={formatDateToYYYYMMDDHHmm} handleMessage={handleMessage}
                          pageSize={pageSize} setPage={setPage} setCurrentPage={setCurrentPage} setPageSize={setPageSize} page={page} totalPages={totalPages}
                          currentPage={currentPage} handlePrevPage={handlePrevPage} handleNextPage={handleNextPage} handleCurrentPageChange={handleCurrentPageChange}
                          inputRef={inputRef} lastAddedCode={lastAddedCode} fetchPromocodeList={fetchPromocodeList}
                      />
                    }

                  </div>
              )) ||
              (selectedPageName === 'GENERATE' &&
                  <Companies data={filteredCompanies} handleSubmit={handleSubmit} loading={loading} fetchCompanies={fetchCompanyInfo}
                             setLoading={setLoading} companies={filteredActualCompanies}
                             fetchPrefixesInfo={fetchPrefixesInfo} companyIsAdded={companyIsAdded}
                            managerCompanies={managerCompanies}
                  />)
          )}
        </div>
        <ToastContainer/>
      </div>
    </div>

  );
};



const handleMessage = (plateNumber, status) => {
  if (!plateNumber) {
    switch (status) {
      case 'ACTIVE':
        return 'Created at: ';
      case 'GIVEN':
        return 'Distributed at: ';
      case 'USED':
        return 'Used at: ';
      default:
        return 'Modified at: ';
    }
  } else {
    return 'Used at: '
  }
};
const mergeActiveMergetList = (activeMergetList) => {
  const merged = {};

  activeMergetList.forEach(item => {
    const key = `${item.type}-${item.expirationHours}-${item.value}-${item.limitValue}`;

    if (!merged[key]) {
      merged[key] = {...item, id: [item.id], activeCount: item.activeCount};
    } else {
      merged[key].activeCount += item.activeCount;
      merged[key].id.push(item.id);
    }
  });
  return Object.values(merged);
}



