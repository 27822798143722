import React from 'react';
import givenYellowIcon from "../../assets/images/givenYellowIcon.svg";
import givenWhiteIcon from "../../assets/Vectors/givenWhite.svg";
import {isMobile, isTablet} from "react-device-detect";

function ChangeStatusButton({
                                qty,
                                setSelectedType,
                                firstCodeByPrefix,
                                isSentToGiven,
                                setIsSentToGiven,
                                onChangeStatus,
                                selectedType
                            }) {
    const getButtonStyle = () => {
        if (qty < 1) {
            return {
                background: '#F8FAFB',
                fontSize: '22px',
                borderRadius: '8px',
                width: '100%'
            };
        }
        return {
            background: isSentToGiven === selectedType ? '#264D99' : '#40C4AA',
            borderRadius: '20px'
        };
    };

    const handleOnClick = () => {
        if (qty < 1) {
            setSelectedType(false);
        } else if (firstCodeByPrefix) {
            isSentToGiven === selectedType ? setIsSentToGiven(false) : onChangeStatus(firstCodeByPrefix);
        }
    };

    return (
        <button
            className={`change-status ${!isMobile ? 'qr-mt-24' : ''} ${qty < 1 && 'text-content'}`}
            style={getButtonStyle()}
            onClick={handleOnClick}
        >
            {qty < 1 ? (
                'Back to menu'
            ) : isSentToGiven === selectedType ? (
                <>
                    <img src={givenYellowIcon} alt="Given" />
                    Next one
                </>
            ) : (
                <>
                    <img src={givenWhiteIcon} alt="Promo code distributed" />
                    Distribute the code
                </>
            )}
        </button>
    );
}

export default ChangeStatusButton;
