// features/generatePromoSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Initial state of the generatePromo feature
const initialState = {
    generatePromo: false,
};

const generatePromoSlice = createSlice({
    name: 'generatePromo',
    initialState,
    reducers: {
        // Action to toggle generatePromo state
        toggleGeneratePromo: (state) => {
            state.generatePromo = !state.generatePromo;
        },
        // Action to specifically set generatePromo to true
        setGeneratePromoTrue: (state) => {
            state.generatePromo = true;

        },
        setGeneratePromoFalse: (state) => {
            state.generatePromo = false;
        },
    },
});

// Export the action creators
export const { toggleGeneratePromo, setGeneratePromoTrue, setGeneratePromoFalse } = generatePromoSlice.actions;

// Export the reducer as default
export default generatePromoSlice.reducer;
