import React from "react";
import trashIcon from '../../assets/images/trash.svg';
import {isMobile} from "react-device-detect";
import {calculateHours, calculateMinutes} from "../../utils/helpers";

const OpenPromoCode = ({ setSelectedPromoPrefix, type, handleDeactivateClick, typesList }) => {
    const left = type.activeCount;
    const ranOut = left === 0;
    const textColor = ranOut ? 'text-danger' : '';
    const promoTitle = `${ranOut ? 'Promo codes ran out' : 'Show'}`;
    const promoText = `${ranOut ? 'Please, contact admin to buy more' : 'promo code'}`;
    return (
        <button className="open-promocode" style={{
            maxHeight: '100%',
            width: typesList.length < 5 ? isMobile ? '100%' : '250px' : '',
            height: typesList.length < 5 ? '250px' : ''
        }} onClick={() => !ranOut && setSelectedPromoPrefix(type)}>
            {ranOut && <button className={'delete-stack'} onClick={() => handleDeactivateClick(type.id)}>
                <img src={trashIcon} alt="Delete stack"/>
            </button>}
            <div className='col center'>
            <span className={`subdued-text ${textColor}`}>{promoTitle}</span>
                <span className={`text-content ${textColor}`}>{type.type !== "BALANCE" ? calculateMinutes(type.value) : `${type.value}$`} </span>
                <span className={`subdued-text`}>{promoText}</span>
                <div style={{display: 'flex', justifyContent: 'space-between', gap: '24px', marginTop: '10px'}}>
                    <div className={'col'}>
                        <span className={'subdued-text'} style={{whiteSpace: 'nowrap'}}>Limit</span>
                        <span className={'text-muted'}>{type.limitValue} user</span>
                    </div>
                    <div className={'col'}>
                        <span className={'subdued-text'} style={{whiteSpace: 'nowrap'}}>Expires after</span>
                        <span className={'text-muted'}>{calculateHours(type.expirationHours)} </span>
                    </div>
                    <div className={'col'}>
                        <span className={'subdued-text'} >Qty</span>
                        <span className={`text-muted ${left < 10 ? 'text-danger' : ''}`}
                              style={{fontSize: '12px'}}>{left} left</span>
                    </div>
                </div>
            </div>
        </button>
    )
}



export default OpenPromoCode;