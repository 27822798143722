import emptyBox from "../../assets/images/empty-box.svg";
import {isMobile, isTablet} from "react-device-detect";
import QRCode from "react-qr-code";
import {calculateHours, calculateMinutes} from "../../utils/helpers";
import givenYellowIcon from "../../assets/images/givenYellowIcon.svg";
import givenWhiteIcon from "../../assets/Vectors/givenWhite.svg";
import React, {useEffect} from "react";
import ChangeStatusButton from "./ChangeStatusButton";
const baseUrl = `${window.location.protocol}//${window.location.host}`;

export const QRSection = ({
                       selectedType,
                       firstCodeByPrefix,
                       info,
                       isSentToGiven,
                       qty,
                       onChangeStatus,
                       setIsSentToGiven,
                       setSelectedType
                   }) => {
    useEffect(() => {
        console.log("Is sent to given: ", isSentToGiven)
    }, [isSentToGiven])
    return (
        <div className={'col center'}>
            <div className={'row center'}>
                <div className={'qr-section'}>
                    {
                        qty < 1 || !firstCodeByPrefix ? (
                                <div className={'col'}>
                                    <div className={'col center empty-promo'}>
                                        <img src={emptyBox}/>
                                        <div className={'col center'} style={{gap: '8px'}}>
                                            <span className={'text-loud'} style={{fontSize: '16px'}}>Promo codes is empty</span>
                                            <span className={'subdued-text'}>Contact admin to get more</span>
                                        </div>
                                    </div>
                                    {
                                        !isMobile && (
                                            <div style={{height: '77px', marginTop: '32px'}}>

                                            </div>
                                        )
                                    }
                                </div>
                            ) :
                            (
                                <div className={'col qr-container'}>
                                    <div className={`col center qr-wrapper ${isSentToGiven === selectedType ? 'given-state' : ''}`} style={{filter: isSentToGiven === selectedType ? 'blur(12px)' : ''}}>
                                          <span className={'text-disabled'}
                                                style={{
                                                    marginBottom: '10px',
                                                    filter: isSentToGiven === selectedType ? 'blur(12px)' : ''
                                                }}
                                          >
                                              Scan QR with camera or Parqour app
                                          </span>
                                        <div className={'qr-code'} >
                                            <QRCode
                                                size={256}
                                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                                value={firstCodeByPrefix ? baseUrl + '/parking/' + info.parkingUid + '/stripe/' + firstCodeByPrefix[1] + `/${firstCodeByPrefix[0]}` : ''}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>
                                    <div className="col" style={{
                                        filter: isSentToGiven === selectedType ? 'blur(12px)' : '',
                                        gap: '4px',
                                        marginTop: isMobile ? '20px' : '',
                                        marginBottom: isMobile ? '40px' : ''
                                    }}>
                                        <div className="col center" style={{gap: '5px', overflow: 'hidden'}}>
                                            <span className="manually-text">or share the code with the guest</span>
                                            <span className="promocode">{firstCodeByPrefix && firstCodeByPrefix[0]}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    <div className={`qr-section-info ${!isMobile ? 'qr-mt-24' : ''}`}>
                        {
                            isMobile ? (
                                <div className={'qr-section-details'}>
                                    <div className={'col'}>
                                        <span className={'subdued-text'} style={{whiteSpace: 'nowrap'}}>Value</span>
                                        <span className={'text-muted'}
                                              style={{fontSize: '24px', color: '#264D99'}}>{selectedType.type !== "BALANCE" ? calculateMinutes(selectedType.value) : `${selectedType.value}$`}</span>
                                    </div>
                                    <div className={'col'}>
                                        <span className={'subdued-text'} style={{whiteSpace: 'nowrap'}}>Limit</span>
                                        <span className={'text-muted'}
                                              style={{fontSize: '18px'}}>{selectedType.limitValue} user{selectedType.limitValue > 1 && 's'}</span>
                                    </div>
                                    <div className={'col'}>
                                        <span className={'subdued-text'}
                                              style={{whiteSpace: 'nowrap'}}>Expires after</span>
                                        <span className={'text-muted'}
                                              style={{fontSize: '18px'}}>{calculateHours(selectedType.expirationHours)} </span>
                                    </div>

                                </div>
                            ) : (
                                <>
                                    <span className={'text-content'}
                                          style={{textAlign: 'center'}}>{selectedType.type !== "BALANCE" ? calculateMinutes(selectedType.value) : `${selectedType.value}$`}</span>
                                    <div className={'qr-section-details'}>
                                    <div className={'col'}>
                                            <span className={'subdued-text'} style={{whiteSpace: 'nowrap'}}>Limit</span>
                                            <span className={'text-muted'}
                                                  style={{fontSize: '16px'}}>{selectedType.limitValue} user{selectedType.limitValue > 1 && 's'}</span>
                                        </div>
                                        <div className={'col'}>
                                            <span className={'subdued-text'} style={{whiteSpace: 'nowrap'}}>Expires after</span>
                                            <span className={'text-muted'}
                                                  style={{fontSize: '16px'}}>{calculateHours(selectedType.expirationHours)} </span>
                                        </div>

                                    </div>
                                </>
                            )
                        }
                        {
                            isMobile && <div className={'row center'}>{QtySection(qty)}</div>
                        }
                    </div>
                    {
                        !isMobile &&
                        <ChangeStatusButton onChangeStatus={onChangeStatus} setSelectedType={setSelectedType}
                                            qty={qty} setIsSentToGiven={setIsSentToGiven} isSentToGiven={isSentToGiven}
                                            firstCodeByPrefix={firstCodeByPrefix} selectedType={selectedType}
                        />
                    }
                </div>
            </div>
            {
                !isMobile && !isTablet ? (
                    QtySection(qty)
                ) : (
                    <div className={'bottom-button'}>
                        <ChangeStatusButton onChangeStatus={onChangeStatus} setSelectedType={setSelectedType}
                                            qty={qty} setIsSentToGiven={setIsSentToGiven} isSentToGiven={isSentToGiven}
                                            firstCodeByPrefix={firstCodeByPrefix} selectedType={selectedType}
                        />
                    </div>
                )
            }

        </div>
    )
}
const QtySection = (qty) => {
    return (
        <div className={'row'} style={{marginTop: '10px', gap: '10px'}}>
            <span className={'subdued-text'}>Quantity</span>
            <span className={`text-muted`}
                  style={{fontSize: '16px'}}>{qty} left</span>
        </div>
    )
}