import CompanyInfoCol from "./CompanyInfoCol";
import NewPromo from "./NewPromo";
import React, {useCallback, useEffect, useRef, useState} from "react";
import arrowIcon from '../../assets/images/tabler-icon-chevron-down.svg';
import optionIcon from '../../assets/images/option.svg'
import emptyCheckbox from '../../assets/images/empty-checkbox.svg'
import {getGenerationHistory} from "../../axios/promocode";
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setGeneratePromoTrue, setGeneratePromoFalse } from '../../features/generatePromoSlice';

import Loader from "../loader/Loader";
import filterIcon from "../../assets/images/tabler-icon-adjustments-horizontal.svg";
import checkedIcon from "../../assets/images/Checkbox.svg";
import EmptyHistory from "./EmptyHistory";
import {calculateHours, calculateMinutes, formatDateToYYYYMMDDHHmm} from "../../utils/helpers";
import DeleteBundlesModal from "./shared/DeleteBundlesModal";
import DropdownMenu from "./DropdownMenu";

const CompanyDetail = ({
                           company, onSelect, handleSubmit, loading, setLoading, fetchCompanies
                        }) => {

    const navigate = useNavigate()
    const [dataLoading, setDataLoading] = useState(false)
    const [filteredTypes, setFilteredTypes] = useState([])
    const [toDelete, setToDelete] = useState(null);
    const [toReduce, setToReduce] = useState(null);
    const [history, setHistory] = useState([]);
    const [selectedOption, setSelectedOption] = useState(false)
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const [filterTypes, setFilterTypes] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const dispatch = useDispatch();
    const generatePromo = useSelector((state) => state.generatePromo.generatePromo);
    const promoGenerated = useSelector((state) => state.promoGenerated.promoGenerated)

    const clickRef = useRef(null)
    const values = {
        'Time-limited': 'WHITE_LIST',
        'Balance based': 'BALANCE'
    }

    const fetchGenerateHistory = useCallback(async (companyId = company.id) => {
        setDataLoading(true);
        try {
            const res = await getGenerationHistory(companyId);
            if (res) {
                res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setHistory(res);
                setFilteredTypes(res);
            } else {
                navigate("/login");
            }
        } catch (error) {
            // Handle any errors here
            console.error('Error fetching data:', error);
        } finally {
            setDataLoading(false);
        }
    }, []);
    useEffect(() => {
        console.log("To delete: ", toDelete)
    }, [toDelete]);
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchGenerateHistory(company.id);
        }, 2000);
        return () => clearTimeout(timer);
    }, [promoGenerated]);

    useEffect(() => {
        fetchGenerateHistory(company.id)
        resetBtn();
    }, [company]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setSelectedOption(false);
                setFilterIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [clickRef]);
    useEffect(() => {
        console.log("To delete: ", toDelete)
    }, [toDelete]);
    const handleFilterByTypes = (e) => {
        const v = e.target.value;
        setFilterTypes(prevState => {
            if (prevState.includes(values[v])) {
                return prevState.filter(type => type !== values[v]);
            } else {
                return [...prevState, values[v]];
            }
        })
    }

    useEffect(() => {
        let filtered;
        if (filterTypes.length === 1) {
            filtered = history.filter(type =>
                type.promoType && type.promoType.type === filterTypes[0]
            );
        }else{
            filtered = history
        }
        setFilteredTypes(filtered);
    }, [filterTypes]);


    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const selectedDate = new Date(value);
        let newDateFrom = dateFrom ? new Date(dateFrom) : new Date();
        let newDateTo = dateTo ? new Date(dateTo) : new Date();

        // Ensure dateFrom is not in the future relative to dateTo
        if (name === "dateFrom") {
            newDateFrom = selectedDate;
            if (newDateFrom > newDateTo) {
                newDateTo = newDateFrom;
            }
        }
        // Ensure dateTo is not in the past relative to dateFrom
        else if (name === "dateTo") {
            newDateTo = selectedDate;
            if (newDateTo < newDateFrom) {
                newDateFrom = newDateTo;
            }
        }

        // Format the dates back to ISO string (yyyy-mm-dd)
        newDateFrom = newDateFrom.toISOString().split('T')[0];
        newDateTo = newDateTo.toISOString().split('T')[0];

        // Update the state
        setDateFrom(newDateFrom);
        setDateTo(newDateTo);

        // Filter the history based on the new dates
        let filteredByDate = history.filter(type => {
            const createdAtDate = new Date(type.createdAt);
            return createdAtDate >= new Date(newDateFrom) && createdAtDate <= new Date(newDateTo);
        });

        setFilteredTypes(filteredByDate);
    };


    const resetBtn = () => {
        setFilterTypes([])
        setFilteredTypes(history)
        setDateTo('')
        setDateFrom('')
    }
    if (!company) {
        return <div />;
    }

    const {
        name,

    } = company;
    const showCircle = filterTypes.length > 0 || dateFrom !== '' || dateTo !== '';
    const circleContent = showCircle ? (
        <div className={'circle'}>
            {filterTypes.length + ((dateFrom !== '' || dateTo !== '') ? 1 : 0)}
        </div>
    ) : null;

    return (
        <div className="item-detail">
            {
                (toDelete || toReduce) &&
                <DeleteBundlesModal
                    type={toDelete || toReduce}
                    setToDelete={setToDelete}
                    fetchHistory={fetchGenerateHistory}
                    fetchCompanies={fetchCompanies}
                    toReduce={toReduce}
                    setToReduce={setToReduce}
                />
            }
            <div className='item-detail-header text-loud' style={{position: 'fixed', zIndex: '10'}}>
                {generatePromo ? (
                    <>
                        <img src={arrowIcon} style={{transform: 'rotate(180deg)', cursor: 'pointer'}} alt="Close Icon"
                             onClick={() => dispatch(setGeneratePromoFalse())}/>
                        New Promo Codes for {name}
                    </>
                ) : (
                        <>
                            {name}
                        </>
                    )}

            </div>

            {
                !dataLoading && !loading ?
                    generatePromo ? <NewPromo company={company} handleSubmit={handleSubmit} loading={loading}
                                              setLoading={setLoading} selectedOption={selectedOption} />
                        :
                        (
                            <>
                                <div className={'col'} style={{padding: '24px', height: '100%', marginTop: '45px'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <button className={'generate-promo-codes'}
                                                onClick={() => dispatch(setGeneratePromoTrue())}>
                                            Generate Promo Codes
                                        </button>
                                        <div className="filter-container" ref={clickRef}>
                                            <button className='filters' onClick={() => setFilterIsOpen(!filterIsOpen)}>
                                                Filters {circleContent} <img src={filterIcon} alt="Toggle Filters"/>
                                            </button>

                                            {filterIsOpen && (
                                                <div className="filter-dropdown">
                                                <label className={'text-normal'}>Date From</label>
                                                    <input style={{marginLeft: '12px'}} type="date" value={dateFrom}
                                                           name="dateFrom" onChange={handleDateChange}   max={new Date().toISOString().split('T')[0]}
                                                    />
                                                    <label className={'text-normal'}>Date To</label>
                                                    <input style={{marginLeft: '12px'}} type="date" value={dateTo}
                                                           name="dateTo" onChange={handleDateChange}/>
                                                    <label className={'text-normal'}>Type</label>
                                                    {['Time-limited', 'Balance based'].map((statusValue) => (
                                                        <label key={statusValue}>
                                                            {statusValue}
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="checkbox"
                                                                    name="status"
                                                                    style={{display: 'none'}}
                                                                    value={statusValue}
                                                                    onChange={handleFilterByTypes}
                                                                />
                                                                {
                                                                    filterTypes.includes(values[statusValue].toUpperCase()) ?
                                                                        <img src={checkedIcon}/> :
                                                                        <img src={emptyCheckbox}/>
                                                                }
                                                            </label>
                                                        </label>
                                                    ))}
                                                    <div className="buttons-group center" style={{borderTop: '1px solid #A4ACB9', marginTop: '10px'}}>
                                                    <span className={'reset-btn'}
                                                          onClick={resetBtn}
                                                    >Reset All</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={'col'} style={{height: '100%'}}>
                                        {
                                            (filteredTypes && filteredTypes.length > 0) ? filteredTypes.map(type => (
                                                    <div className='item-detail-info' style={{background: type.activePromoCodes > 0 ? '#FFF' : '#FFF0F3'}}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            position: 'relative'
                                                        }}>
                                                            <span className={'text-loud'} style={{fontSize: '20px'}}>
                                                                {`${type.promoType.type === 'BALANCE' ? '$' : ''}${type.promoType.type !== 'BALANCE' ? calculateMinutes(type.promoType.value) : type.promoType.value}`}
                                                            </span>
                                                            <img src={optionIcon} style={{cursor: 'pointer'}}
                                                                 onClick={() => {
                                                                     setSelectedOption(prev => prev && prev.generationId === type.generationId ? null : type);
                                                                 }}
                                                            />
                                                            <DropdownMenu
                                                                setToDelete={setToDelete}
                                                                type={type}
                                                                clickRef={clickRef}
                                                                selectedOption={selectedOption}
                                                                setToReduce={setToReduce}
                                                            />

                                                        </div>

                                                        <div className='history-item-info' style={{width: '100%'}}>
                                                            <CompanyInfoCol label="Active" value={type.activePromoCodes}
                                                                            generatedHistory={true}/>
                                                            <CompanyInfoCol label="Used" value={type.usedPromoCodes}
                                                                            generatedHistory={true}/>
                                                            <CompanyInfoCol label="Given" value={type.givenPromoCodes}
                                                                            generatedHistory={true}/>
                                                            <CompanyInfoCol label="Expired"
                                                                            value={type.expiredPromoCodes}
                                                                            generatedHistory={true}/>
                                                            <span style={{marginRight: '30px'}}>
                                            <CompanyInfoCol label="Total" value={type.promoCodeAmount}
                                                            generatedHistory={true}/>
                                        </span>
                                                        </div>
                                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <div className={'col'} style={{flex: '1'}}>
                                                                {type && type.promoType && (
                                                                    <>
                                                                        <CompanyInfoRow labels={["Types", "Value"]}
                                                                                        values={[
                                                                                            type.promoType.type === 'BALANCE' ? 'Balance based' : 'Time-Limited',
                                                                                            `${type.promoType.type === 'BALANCE' ? '$' : ''}${type.promoType.type !== 'BALANCE' ? calculateMinutes(type.promoType.value) : type.promoType.value}`

                                                                                            ]}
                                                                                        value={type.promoType.type === 'BALANCE' ? 'Balance based' : 'Time-Limited'}/>

                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className={'col'} style={{flex: '1'}}>
                                                                {type && type.promoType && (
                                                                    <>
                                                                        <CompanyInfoRow labels={["Usage Limit", "Expiration Time"]}
                                                                                        values={[
                                                                                            `${type.promoType.limitValue}`,
                                                                                            `${calculateHours(type.promoType.expirationHours)}`
                                                                                        ]}
                                                                                        />

                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={'text-disabled'}>
                                                            generated {formatDateToYYYYMMDDHHmm(type.createdAt)}
                                                        </div>

                                                    </div>
                                                ))
                                                : history.length > 0 ?
                                                <EmptyHistory
                                                    title={'No promo codes found'}
                                                    description={'Try adjusting your search or filter to find what your are looking for.'}
                                                    clickHandler={resetBtn}
                                                    buttonClassName={'cus-button text-content'}
                                                    buttonName={'Reset all filters'}
                                                /> :
                                                <EmptyHistory
                                                    title={'No promo codes yet'}
                                                    description={'Add promo codes for this company'}
                                                    clickHandler={() => dispatch(setGeneratePromoTrue())}
                                                    buttonClassName={'generate-promo-codes'}
                                                    buttonName={'Generate Promo Codes'}
                                                />
                                                // (
                                                //     <div className={'company-no-history'}>
                                                //         <div className={'col center'}>
                                                //             <img src={notFound}/>
                                                //             <div className={'col center'}>
                                                //                 <span
                                                //                     className={'text-loud'}>No promo codes found</span>
                                                //                 <span className={'subdued-text'}>Try adjusting your search or filter to find what your are looking for.</span>
                                                //             </div>
                                                //             <button className={'cus-button text-content'}
                                                //                     onClick={resetBtn} style={{fontSize: '14px'}}>Reset
                                                //                 all filters
                                                //             </button>
                                                //         </div>
                                                //     </div>
                                                // )
                                        }
                                    </div>


                                </div>
                            </>
                        )
                    :
                    <div className={'col center'} style={{height: '100%'}}>
                        <Loader/>
                    </div>
            }


        </div>
    );
}


const CompanyInfoRow = ({labels, values}) => (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className={'col'} style={{flex: '1', textAlign: 'start', gap: '5px'}}>
            {labels.map(label => (
                <span className='subdued-text'>{label}</span>
            ))}

        </div>
        <div className={'col'} style={{flex: '2', textAlign: 'start'}}>
            {values.map(value => (
                <span className="text-loud" style={{fontSize: '14px', textAlign: 'unset'}}>{value}</span>
            ))}

        </div>
    </div>
    // <div style={{display: 'flex', justifyContent: 'space-between'}}>
    //     <span className='subdued-text' style={{flex: '2', textAlign: 'start'}}>{label}</span>
    //     <span style={{flex: '3'}}>{value}</span>
    // </div>
);


export default CompanyDetail;
