import React from "react";

export const NoData = ({src, title, desc, classNames}) => {
    const additionalClassNames = classNames.join(' ');

    return (
        <div className={`col center ${additionalClassNames}`}>
            <img src={src}/>
            <div className={'col center'} style={{gap: '8px'}}>
                <span className={'text-loud'} style={{fontSize: '16px'}}>{title}</span>
                <span className={'subdued-text'}>{desc}</span>
            </div>
        </div>
    )
}