import React from "react";
import {currencySymbols} from "../../static/static";
import {ParkingStatus} from "../../enums/responseStatus";
import {calculateStayDuration, formatCheckInDate} from "../../utils/helpers";

export const PaymentInfo = ({parkingUid, info, plateNumber}) => {
    const isUae = localStorage.getItem('isUae');

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
              <span className="infoCar_name" style={{ marginBottom: "8px" }}>
                {parkingUid === "112" && "801 S Grand Ave garage"}
                  {parkingUid === "113" && "NAU Texas"}
                  {parkingUid === "118" && "Aurora Tower"}
              </span>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "15px",
                }}
            >

                <div className={'info-wrapper'}>
                    {
                        info.result === ParkingStatus.SUCCESS &&
                        (
                            <div className={'info-list'}>
                                <span className={'subdued'}>Balance</span>
                                <span className={'value'}>{info?.currentBalance
                                    ?.toLocaleString("en")
                                    .replace(/,/g, " ") || info.current_balance}{" "}
                                    {currencySymbols[info?.currency?.toUpperCase()]}</span>
                            </div>
                        )
                    }
                    {
                        info?.inDate && (
                            <div className={'info-list'}>
                                <span className={'subdued'}>Check-in time</span>
                                <span className={'value'}>{formatCheckInDate(info.inDate)}</span>
                            </div>
                        )
                    }
                    <div className={'info-list'}>
                        <span className={'subdued'}>Vehicle number</span>
                        <span className={'value'}>{plateNumber}</span>
                    </div>
                    {
                        (isUae && info.result !== ParkingStatus.SUCCESS) ?
                            (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '40px 0'}}>
                                    <div className={'col'}>
                                        <span className={'text-normal'}>Free exit before</span>
                                        <span style={{textAlign: 'center'}}>
                                            {
                                                formatCheckInDate(
                                                    new Date(new Date(info.inDate).getTime() + ((parkingUid === "129" ? 15 : 10) * 60000)).toISOString()
                                                )
                                            }
                                        </span>
                                    </div>
                                </div>
                            ) :
                            (
                                <>
                                    <div className={'info-list'}>
                                        <span className={'subdued'}>Duration</span>
                                        <span className={'value'}>{calculateStayDuration(info.inDate)}</span>

                                    </div>
                                    <div className={'info-list'}>
                                        <span className={'amount'}>Amount</span>
                                        <span className={'amount'}
                                              style={{textAlign: 'right'}}>{info?.sum}{" "}
                                            {currencySymbols[info?.currency?.toUpperCase()]}</span>

                                    </div>
                                </>
                            )
                    }

                </div>

            </div>
        </>
    )
}
