import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {isMobile} from "react-device-detect";
import React, {useState, useEffect} from "react";
import Loader from "../../components/loader/Loader";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SwitchButton from "../../components/SwitchButton/SwitchButton";
import {currencySymbols} from "../../static/static";
import {calculateStayDuration, formatCheckInDate} from "../../utils/helpers";
export const SubscriptionInfo = ({
                                     info,
                                     plateNumber,
                                     selectedSubscription,
                                     setSelectedSubscription,
                                     subscriptions,
                                     setDate,
                                     setSubscriptionAmount,
                                     setInnerParkingId,
                                     innerParkings,
                                     parkingUid


}) => {
    const [isCurrentSession, setIsCurrentSession] = useState(true)
    const [isActive, setIsActive] = useState(false);
    const [activeSubscriptions, setActiveSubscriptions] = useState([])
    const [activeParking, setActiveParking] = useState([])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? 3 : 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        arrows: false
    };
    let subscriptionDisabled = localStorage.getItem('region') === 'Saudi Arabia';

    const handleParkingChange = (e) => {
        const parkingId = Number(e.target.value);
        setInnerParkingId(parkingId);

        const filteredSubscriptions = subscriptions?.filter((subscription) => subscription?.parkingId === parkingId);
        setActiveSubscriptions(filteredSubscriptions);
    }

    useEffect(() => {
        const filteredParking = innerParkings?.filter((p) => {
            for (let i = 0; i < subscriptions.length; i++) {
                if (subscriptions[i]?.parkingId === p?.id) return true;
            }
            return false;
        });

        setActiveParking(filteredParking);

        const filteredSubscriptions = subscriptions.filter((subscription) => subscription?.parkingId === filteredParking[0]?.id);
        setActiveSubscriptions(filteredSubscriptions);
    }, []);

    return (
        <div className={'subscription-info-wrapper'}>
            {
                !subscriptionDisabled ?
                    !activeSubscriptions ?
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}><Loader /></div>
                    :
                    (
                        <>
                            <div className={'plans'}>
                                Plans
                                <Slider {...settings}>
                                    {
                                        activeSubscriptions.map(sub => (
                                            <div className={`plan ${selectedSubscription.id === sub.id && 'plan-active'} mr-5`} onClick={() => setSelectedSubscription(sub)}>
                                                <span className={'period-days'}>
                                                  {`${sub.period} day${sub.period > 1 ? 's' : ''}`}
                                                </span>
                                                <span>{sub.price} {currencySymbols[info?.currency?.toUpperCase()]}</span>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                          {/*------*/}
                            {
                                info.result === 'error' ? (
                                    <div className={'date-wrapper'}>
                                        <label>Starts from</label>
                                        <MobileDateTimePicker
                                            format={parkingUid === "118" ? 'LLL' : 'MM/DD/YYYY'}
                                            onChange={(e) => {
                                                console.log("Date: ", e.toDate())
                                                setDate(e.toDate())
                                            }}
                                            defaultValue={dayjs()}
                                        />
                                    </div>

                                ) : (
                                    <div className={'date-wrapper'}>
                                        <label>Starts from</label>
                                        <div className={'session-option'}>
                                            <div className={`current-session ${isCurrentSession && 'active'}`} onClick={() => setIsCurrentSession(true)}>
                                                Current session
                                            </div>
                                            <div className={`current-session ${!isCurrentSession && 'active'}`} onClick={() => setIsCurrentSession(false)}>
                                                Choose a date
                                            </div>
                                        </div>
                                        {
                                            !isCurrentSession && (
                                                <>
                                                    <MobileDateTimePicker
                                                        format={parkingUid === "118" ? 'LLL' : 'MM/DD/YYYY'}

                                                        onChange={(e) => {
                                                            setDate(e.toDate())
                                                        }}

                                                        defaultValue={dayjs()}
                                                    />
                                                    <div className={'include-section'}>
                                                        <div className={'switch-wrapper'}>
                                                            <span>Include unpaid session into payment</span>
                                                            <SwitchButton isActive={isActive} setIsActive={setIsActive} />
                                                        </div>
                                                        <span className={'subdued'}>Turn on to pay both for parking and subscription</span>
                                                    </div>
                                                </>

                                            )
                                        }
                                    </div>
                                )
                            }

                            {
                                activeParking.length > 0 && (
                                    <div className={'select-inner-parkings'}>
                                        <label>Select Inner Parking</label>
                                        <select onChange={handleParkingChange}>
                                            {activeParking.map(p => (
                                                p.name !== "Aurora Tower_Basement" && (
                                                    <option key={p.id} value={p.id}>
                                                        {p.name}
                                                    </option>
                                                )
                                            ))}

                                        </select>
                                    </div>
                                )
                            }

                            <div className={'info-wrapper'}>
                                {
                                    info.result !== 'error' ? (
                                        <>
                                            <div className={'info-list'}>
                                                <span className={'subdued'}>Balance</span>
                                                <span className={'value'} style={{display: 'flex'}}>
                                                    {info?.currentBalance
                                                        ?.toLocaleString("en")
                                                        .replace(/,/g, " ") || info.current_balance}{" "}
                                                    {currencySymbols[info?.currency?.toUpperCase()]}
                                                </span>
                                            </div>

                                            <div className={'info-list'}>
                                                <span className={'subdued'}>Vehicle number</span>
                                                <span className={'value'}>{plateNumber}</span>
                                            </div>
                                            {
                                                (isCurrentSession || isActive) && (
                                                    <>
                                                        {
                                                            info?.inDate && (
                                                                <div className={'info-list'}>
                                                                    <span className={'subdued'}>Check-in time</span>
                                                                    <span className={'value'}>{formatCheckInDate(info.inDate)}</span>
                                                                </div>
                                                            )
                                                        }
                                                        {/*<div className={'info-list'}>*/}
                                                        {/*    <span className={'subdued'}>Current session started:</span>*/}
                                                        {/*    <span*/}
                                                        {/*        className={'value'}>{info?.inDate || info?.in_date ? formatDate(info.inDate || info.in_date) : "No entry"}</span>*/}
                                                        {/*</div>*/}
                                                        <div className={'info-list'}>
                                                            <span className={'subdued'}>Duration: </span>
                                                            <span className={'value'}>{calculateStayDuration(info.inDate)}</span>

                                                        </div>
                                                        <div className={'info-list'}>
                                                            <span className={'subdued'}>For current session:</span>
                                                            <span className={'value'} style={{display: 'flex', gap: '3px'}}>
                                                                {
                                                                    !isActive && (
                                                                        <span className={'subdued'}
                                                                              style={{
                                                                                  textDecorationLine: 'line-through',
                                                                                  marginRight: '5px'
                                                                              }}>
                                                                            {currencySymbols[info?.currency?.toUpperCase()]}{info?.sum}
                                                                        </span>
                                                                    )
                                                                }
                                                                <span>{currencySymbols[info?.currency?.toUpperCase()]}{isActive ? info?.sum : '0'} </span>
                                                            </span>
                                                        </div>
                                                        <div className={'info-list'}>
                                                            <span className={'subdued'}>Subscription:</span>
                                                            <span className={'value'}>
                                                                {currencySymbols[info?.currency?.toUpperCase()]}{selectedSubscription.price}
                                                            </span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className={'info-list'}>
                                                <span className={'amount'}>To pay: </span>
                                                <span className={'amount'} style={{textAlign: 'right'}}>
                                                    {currencySymbols[info?.currency?.toUpperCase()]}
                                                    <CalculatePayment
                                                        selectedSubscription={selectedSubscription}
                                                        isActive={isActive} info={info}
                                                        setSubscriptionAmount={setSubscriptionAmount}/>

                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <div className={'info-list'}>
                                            <span className={'amount'}>To pay</span>
                                            <span className={'amount'} style={{textAlign: 'right'}}>
                                                {currencySymbols[info?.currency?.toUpperCase()]}{selectedSubscription.price}
                                                {/*{parkingInfo.currency}*/}
                                            </span>
                                        </div>
                                    )
                                }

                            </div>
                        </>
                    )
                :
                    (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '195px'}}>
                            <span style={{textAlign: 'center', color: '#EC1C24',width: '50%'}}>Apologies, we are not yet able to create a permit for a car in this region.</span>
                        </div>
                    )
            }

        </div>
    )
}

const CalculatePayment = ({isActive, selectedSubscription, info, setSubscriptionAmount}) => {
    let payment;

    if (isActive) {
        payment = selectedSubscription.price + info.sum;
    } else if (selectedSubscription.name === 'fake') {
        payment = 0;
    } else if (info?.currentBalance >= 0) {
        if (info.currentBalance >= selectedSubscription.price) {
            payment = 0;
        } else {
            payment = Math.abs(info.currentBalance - selectedSubscription.price);
        }
    } else {
        payment = Math.abs(info.currentBalance - selectedSubscription.price);
    }
    setSubscriptionAmount(payment);
    return (
        <>{payment}</>
    );
}






{/*   <div className={'unpaid-session'}>*/}
{/*       <div className={'unpaid-description'}>*/}
{/*           <span className={'value'}>You have unpaid session</span>*/}
{/*           <span className={'subdued'}>Subscription will not cover your current session, because it started a week ago. If you won’t pay for current session, we can’t let you leave parking</span>*/}
{/*       </div>*/}
{/*       <div className={'unpaid-info'}>*/}
{/*           <div className={'info-list'}>*/}
{/*               <span className={'subdued'}>Start date(?): </span>*/}
{/*               <span className={'value'}>{info?.inDate || info?.in_date ? formatDate(info.inDate || info.in_date) : "No entry"} </span>*/}
{/*           </div>*/}

{/*           <div className={'info-list'}>*/}
{/*               <span className={'subdued'}>Duration: </span>*/}
{/*               <span className={'value'}>{info?.durationInMinutes ? (*/}
{/*                   info.in_date === ""*/}
{/*                       ? "No entries"*/}
{/*                       : Math.abs(info.durationInMinutes) < 60*/}
{/*                           ? Math.abs(info.durationInMinutes) + " minutes"*/}
{/*                           : Math.floor(Math.abs(info.durationInMinutes) / 60) +*/}
{/*                           ` ${info.durationInMinutes > 1 ? "hours" : "hour"} and ` +*/}
{/*                           (Math.abs(info.durationInMinutes) % 60) +*/}
{/*                           " minutes"*/}
{/*               ) : "0"}*/}
{/*</span>*/}
{/*           </div>*/}
{/*           <div className={'info-list'}>*/}
{/*               <span className={'amount'}>Amount</span>*/}
{/*               <span className={'amount'} style={{textAlign: 'right'}}>{info.sum?.toLocaleString("en").replace(/,/g, " ")}{" "}*/}
{/*                   {info?.currency?.toUpperCase()}</span>*/}
{/*           </div>*/}
{/*       </div>*/}
{/*       <div className={'unpaid-switch-container'}>*/}

{/*       </div>*/}
{/*   </div>*/}