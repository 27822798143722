import React from 'react';
import chevronRight from '../../../assets/images/tabler-icon-chevron-down.svg'
import chevronRightActive from '../../../assets/images/chevron-right-active.svg'

const Pagination = ({ pageSize, setPage, setCurrentPage, setPageSize, page, totalPages, currentPage, handlePrevPage, handleNextPage, handleCurrentPageChange, inputRef }) => {
    return (
        <div className='promo-pagination'>
            <div className={'page-size'}>
                <span>Show by</span>
                <select style={{width: '50px'}} value={pageSize} onChange={(e) => {
                    setPage(1);
                    setCurrentPage(1);
                    setPageSize(e.target.value);
                }}>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                </select>
            </div>

            <span className="page-info">
                <button className={'btn-none rotate'} style={{marginBottom: '5px'}} onClick={handlePrevPage} disabled={page === 1}>
                    {page === 1 ? <img src={chevronRight} alt="Prev"/> : <img src={chevronRightActive} alt="Prev Active"/>}
                </button>
                <span style={{display: 'flex', gap: '8px'}}>Page
                    <input
                        className={'pagination-input'}
                        ref={inputRef}
                        type="number" min={1} max={totalPages} value={currentPage}
                        onChange={handleCurrentPageChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setPage(e.target.value);
                                setCurrentPage(e.target.value);
                            }
                        }}
                    /> of {totalPages}
                </span>
                <button className={'btn-none'} onClick={handleNextPage} disabled={page === totalPages}>
                    {page === totalPages ? <img src={chevronRight} alt="Next"/> : <img src={chevronRightActive} alt="Next Active"/>}
                </button>
            </span>
        </div>
    );
};

export default Pagination;
