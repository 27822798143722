export const calculateHours = (amount) => {
    if (amount < 24) {
        return `${amount} hour${amount !== 1 ? 's' : ''}`;
    } else if (amount >= 24 && amount < 730) { // Less than 1 month
        const days = Math.floor(amount / 24);
        return `${days} day${days !== 1 ? 's' : ''}`;
    } else if (amount >= 730 && amount < 8760) { // Less than 1 year
        const months = Math.floor(amount / 730); // Approximate number of hours in a month
        return `${months} month${months !== 1 ? 's' : ''}`;
    } else { // 1 year or more
        const years = Math.floor(amount / 8760); // Number of hours in a year
        return `${years} year${years !== 1 ? 's' : ''}`;
    }
};

export const calculateMinutes = (amount) => {
    if (amount < 60) {
        return `${amount} min`;
    } else if (amount >= 60 && amount < 1440) {
        const hours = amount / 60;
        return `${hours} h`;
    } else {
        const days = amount / 60 / 24;
        return `${days} day${days === 1 ? '' : 's'}`;
    }
}

export const formatDateToYYYYMMDDHHmm = (dateString) => {
    let formattedDate;
    if (typeof dateString !== 'string'){ // coming from local created new Date instance
        let newFormattedDate = formatToApiDate(dateString)
        formattedDate = new Date(newFormattedDate);

    }else{
        if (dateString === "") return "";

        // Convert the input date to a Date object
        const inputDate = new Date(dateString);
        // Create a new Date object representing the same moment in time in the local timezone
        formattedDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000);

    }
    const year = formattedDate.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const month = monthNames[formattedDate.getMonth()]; // getMonth() is zero-based
    const day = String(formattedDate.getDate()).padStart(2, "0");

    // Convert 24h to 12h format
    let hours = formattedDate.getHours();
    const amPm = hours >= 12 ? 'p.m' : 'a.m';
    hours = hours % 12;
    hours = hours || 12; // Convert '0' to '12' for 12 AM
    const formattedHours = String(hours).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, "0");

    return `${day} ${month} ${year} ${formattedHours}:${minutes} ${amPm}`;

};
export const formatToApiDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};



export const formatCheckInDate = (inDate) => {
    const inputDate = new Date(inDate);
    const now = new Date();
    const formatAMPM = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'p.m.' : 'a.m.';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`;
    };

    const formatDateComponents = (date) => {
        const day = date.getDate();
        const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.",
            "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };

    if (inputDate.toDateString() === now.toDateString()) {
        return formatAMPM(inputDate);
    }

    return `${formatDateComponents(inputDate)} ${formatAMPM(inputDate)}`;
}

export const calculateStayDuration = (inDate) => {
    if(!inDate) return "No data";

    const inDateTime = new Date(inDate);
    const now = new Date();
    const diffInMs = now - inDateTime;

    // Convert milliseconds to minutes, hours, and days
    const minutes = Math.floor(diffInMs / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} days ${hours % 24} hours ${minutes % 60} minutes`;
    } else if (hours > 0) {
        return `${hours} hours ${minutes % 60} minutes`;
    } else {
        return `${minutes} minutes`;
    }
}

