import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosGetPromocodeInfo } from "../../axios/promocode";
import logo from "../../assets/images/mainLogo.png";
import LoadingSpinner from "../../components/loader/Spinner";
import "./CheckPromocode.css";
import {formatDateToYYYYMMDDHHmm} from "../../utils/helpers";



function CheckPromocode() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [promocodeVal, setPromocodeVal] = useState("");
  const [promocodeInfo, setPromocodeInfo] = useState();

  const checkPromocode = () => {
    setLoading(true);
    axiosGetPromocodeInfo(promocodeVal)
      .then((res) => {
        setPromocodeInfo(res);
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          navigate("/login");
        }
        setPromocodeInfo(null)
      })
      .finally(() => setLoading(false));
  };

  const handleMessage = (plateNumber, status) => {
    if (!plateNumber) {
      switch (status) {
        case 'ACTIVE': return 'Created at: ';
        case 'GIVEN': return 'Given at: ';
        case 'USED': return 'Promo Code Redeemed. Last Used at: ';
        default: return 'Modified at: ';
      }
    }else{
      return 'Used at: '
    }
  };


  return (
      <div className={'middle'}>
        <div className="container responsive_width">
          <div className={"welcome_div"}>
            <div>
              <img src={logo} alt=""/>
            </div>
            <span>Check promo code</span>
          </div>
          <div className="promocode-container">
            <div className="promocode-container">
              <input
                  type="text"
                  placeholder={"XXX-ABCDE or ABCDE"}
                  name="promocode"
                  value={promocodeVal}
                  onChange={(e) => setPromocodeVal(e.target.value?.toUpperCase())}
              />
              <label htmlFor="promocode">Promo code:</label>
            </div>

            <button
                type="button"
                onClick={checkPromocode}
                className={`infoCar_button bg-primary`}
                disabled={loading}
            >
              {loading ? <LoadingSpinner/> : "Apply"}
            </button>
          </div>
          <div className={"check-form"} style={{height: "300px", marginTop: '5px'}}>
            {loading ? (
                <div style={{display: "flex", justifyContent: "center"}}>
                  <LoadingSpinner/>
                </div>
            ) : promocodeInfo ? (
                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                >
                  <div className="infoCar_info">
                    <span>Status: </span>
                    <span>{promocodeInfo?.status}</span>
                  </div>

                  <div className="infoCar_info">
                    <span>{handleMessage(promocodeInfo?.plateNumber, promocodeInfo?.status)}</span>
                    <span>
                {promocodeInfo?.modifiedAt
                    ? formatDateToYYYYMMDDHHmm(promocodeInfo?.modifiedAt)
                    : "--"}
              </span>
                  </div>
                  <div className="infoCar_info">
                    <span>Company: </span>
                    <span>{promocodeInfo?.toCompany}</span>
                  </div>
                  <div className="infoCar_info">
                    <span>Limit value: </span>
                    <span>{promocodeInfo?.limitValue}</span>
                  </div>
                  <div className="infoCar_info">
                    <span>Plate number: </span>
                    <span>{promocodeInfo?.plateNumber}</span>
                  </div>

                  <div className="infoCar_info">
                    <span>Expires at: </span>
                    <span>
                {promocodeInfo?.expiresAt
                    ? formatDateToYYYYMMDDHHmm(promocodeInfo?.expiresAt)
                    : "--"}
              </span>
                  </div>
                </div>
            ) : (
                <>Not found</>
            )}
          </div>
        </div>
      </div>

  );
}

export default CheckPromocode;
