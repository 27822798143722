import React, {useEffect, useState} from 'react';
import Filter from './Filter';
import Pagination from './Pagination';
import SelectedCode from './SelectedCode';
import {PromoInfo} from "./PromoInfo";

import closeGrayIcon from '../../../assets/Vectors/closeGray.svg';
import emptyBox from '../../../assets/images/empty-box.svg'
import Loader from "../../loader/Loader";
import searchIcon from "../../../assets/images/search24.svg";
import {axiosGetPromocodeInfo, axiosGetPublicPromoCodeInfo} from "../../../axios/promocode";
import {useNavigate} from "react-router-dom";
import HistoryItem from "./HistoryItem";


const PromoListHistory = ({
                       typesList, clickRef, filterIsOpen, setFilterIsOpen, dateFrom, dateTo, handleDateChange, status,
                       handleFilterByStatus, handleResetAll, setSelectedType, setChangeStatus,
                       promocodesLoading, promocodes, selectedCode, setSelectedCode, formatDateToYYYYMMDDHHmm,
                       handleMessage, pageSize, setPage, setCurrentPage, setPageSize, page, totalPages, currentPage,
                       handlePrevPage, handleNextPage, handleCurrentPageChange,   inputRef, lastAddedCode,
                      fetchPromocodeList
                        }) => {
    const [inputValue, setInputValue] = useState('');
    const [promoCodeInfo, setPromoCodeInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (inputValue.length === 0) {
            fetchPromocodeList(inputValue);
            return;
        }
        const timer = setTimeout(() => {
            if (inputValue.length >= 2) {
                fetchPromocodeList(inputValue);
            }
        }, 300);

        return () => clearTimeout(timer);
    }, [inputValue, fetchPromocodeList]);
    return (
        <div className='promo-right-side'>
            {typesList && (
                <div className={'filter-search'}>
                        <label style={{position: 'relative'}}>
                            <img src={searchIcon || ''} className='magnifier' alt={'Search icon'}/>
                            <input placeholder={'Search...'} className='search-input' style={{background: 'none', fontSize: '16px'}}
                                   value={inputValue}
                                   onChange={(e) => setInputValue(e.target.value.toUpperCase())}
                            />

                        </label>
                        
                    <Filter
                        clickRef={clickRef}
                        filterIsOpen={filterIsOpen}
                        setFilterIsOpen={setFilterIsOpen}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        handleDateChange={handleDateChange}
                        status={status}
                        handleFilterByStatus={handleFilterByStatus}
                        handleResetAll={handleResetAll}
                        setSelectedType={setSelectedType}
                        setChangeStatus={setChangeStatus}
                    />
                </div>
            )}

            <div className='promo-list' style={{height: !typesList ? '100%' : ''}}>
                {promocodesLoading ? (
                    <div className={'col center'} style={{background: 'none', height: '100%'}}><Loader/></div>
                ) : promocodes.length > 0 && promoCodeInfo !== false ? (
                        inputValue.length > 0 && promoCodeInfo !== null ?
                            <HistoryItem setSelectedCode={setSelectedCode} lastAddedCode={lastAddedCode} p={promoCodeInfo} selectedCode={selectedCode} handleMessage={handleMessage} />
                            : promocodes.slice().sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt)).map(p => (
                                <HistoryItem
                                    setSelectedCode={setSelectedCode}
                                    lastAddedCode={lastAddedCode}
                                    p={p}
                                    handleMessage={handleMessage}
                                    selectedCode={selectedCode}
                                />
                            ))
                ) : (
                    <div className={'col center'} style={{height: '100%', gap: '20px'}}>
                        <img src={emptyBox || ''} alt="No Promo Codes"/>
                        <div className={'col center'} style={{gap: '8px'}}>
                            <span className={'text-loud'} style={{fontSize: '16px'}}>Promo codes history is empty</span>
                            <span className={'subdued-text'}>They will appear here soon...</span>
                        </div>
                    </div>
                )}
            </div>

            {(typesList && promoCodeInfo === null && !promocodesLoading) && (
                <Pagination
                    pageSize={pageSize}
                    setPage={setPage}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    page={page}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    handleCurrentPageChange={handleCurrentPageChange}
                    inputRef={inputRef}
                />
            )}
        </div>
    );
};

export default PromoListHistory;
