import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

import PaymentForm from "./components/PaymentForm/PaymentForm";
import EnterCarForm from "./pages/EnterCarForm/EnterCarForm";
import InfoCar from "./pages/InfoCar/InfoCar";
import SuccessPage from "./pages/MessagePages/SuccessPage";
import ErrorPage from "./pages/MessagePages/ErrorPage";
import Login from "./pages/Login/Login";
import { PromoCode } from "./pages/PromoCodes/PromoCode";
import { GeneratePromoCodePrint } from "./components/GeneratePromoCodePrint/GeneratePromoCodePrint";
import rightGroup from "./assets/Vectors/RightAngle.png";
import leftGroup from "./assets/images/leftGroup.svg";
import bottomGroup from "./assets/Vectors/BottomGroup.png";
import "./App.css";
import CheckPromocode from "./pages/CheckPromocode/CheckPromocode";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from "react";

function App() {
  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <BrowserView>
            <img src={rightGroup} className="rightGroup"/>
            <picture>
              <source
                  srcset={leftGroup}
                  className="leftGroup"
                  media="(min-width: 768px)"
              />
              <img src={leftGroup} className="leftGroup"/>
            </picture>
          </BrowserView>

          <MobileView>
            {/*<img src={bottomGroup} className="bottomGroup" />*/}
            <img src={rightGroup} className="rightGroup"/>
            <img src={leftGroup} className="leftGroup"/>
          </MobileView>
          <Routes>
            <Route path="/parking/:parkingUid/payment" element={<PaymentForm/>}/>
            <Route
                path="/parking/:parkingUid/:paymentMethod"
                element={<EnterCarForm/>}
            />
              <Route
                  path="/parking/:parkingUid/:paymentMethod/:id/:promo"
                  element={<EnterCarForm />}
              />
            <Route
                path={"/uae/parking/:parkingUid/:paymentMethod"}
                element={<EnterCarForm/>}
            />
            <Route
                path="/parking/:parkingUid/info"
                element={<InfoCar qr={false}/>}
            />
            <Route
                path="/parking/:parkingUid/info/:plateNumber/:paymentMethod/:rateType"
                element={<InfoCar qr={true}/>}
            />

        <Route path="/payment-success" element={<SuccessPage />} />
        <Route path="/payment-error" element={<ErrorPage />} />
        <Route path="/login" element={<Login />} />
              <Route path="/generate/promo-code" element={<PromoCode />} />
        <Route
          path="/generate/promo-code/print"
          element={<GeneratePromoCodePrint />}
        />
        {/*<Route path="/promo-code/types" element={<PromoTypes />} />*/}
        <Route path="/check-promocode" element={<CheckPromocode />} />
      </Routes>
    </BrowserRouter>
  </LocalizationProvider>

  );
}

export default App;
