// reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import generatePromoReducer from '../features/generatePromoSlice';
import promoGeneratedReducer from '../features/promoGeneratedSlice'
import uaeCarFormReducer from '../features/uaeCarFormSlice'

const rootReducer = combineReducers({
    generatePromo: generatePromoReducer,
    promoGenerated: promoGeneratedReducer,
    uaeCarForm: uaeCarFormReducer
});

export default rootReducer;
