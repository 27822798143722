import axios from "axios";

const api = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_PARQOUR,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(
    (config) => {
      console.log("Отправлен запрос:", config);
      return config;
    },
    (error) => {
      console.error("Ошибка при отправке запроса:", error);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      console.log("Получен ответ:", response);
      return response;
    },
    (error) => {
      console.error("Ошибка при получении ответа:", error);
      return Promise.reject(error);
    }
  );
  return instance;
};

export default api();
