import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/images/parqour-logo.svg";
import {axiosBillingTokenFunc, billingInfoFunc, getParkingByUid, stripePaymentCheck,} from "../../axios/axiosRequest";
import {axiosChangePromocodeStatus, axiosSendPromocode, savePromoCode} from "../../axios/promocode";
import "./EnterCarForm.css";
import LoadingSpinner from "../../components/loader/Spinner";
import UaeCarForm from "../../components/uaeCarForm/UaeCarForm";
import {regionCodes} from "../../static/static";
import {useSelector} from "react-redux";
import {ErrorType} from '../../enums/errorTypes';
import {isIOS} from "react-device-detect";
import Loader from "../../components/loader/Loader";



function EnterCarForm() {
  const navigate = useNavigate();
  let { parkingUid, paymentMethod, promo, id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const { region, code, reverse } = useSelector((state) => state.uaeCarForm);
  const [parkingInfo, setParkingInfo] = useState(null);
  const [plateNumber, setPlateNumber] = useState(
    localStorage.getItem("plateNumber") || ""
  );
  const [uaeSelectedFields, setUaeSelectedFields] = useState({
    region: 'Dubai',
    code: '',
    reverse: false
  })
    const [promocode, setPromocode] = useState(promo || "");
  const [error, setError] = useState({
    msg: "",
    type: ""
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  // const token = localStorage.getItem("billingToken");

  const isUae = window.location.pathname.split('/')[1] === 'uae';
  isUae ? localStorage.setItem('isUae', 'true') : localStorage.getItem('isUae') && localStorage.removeItem("isUae");

  if(isIOS && promo && searchParams.get('appInstalled') !== "no"){
    const currentLocation = window.location;
    window.location = `parqourScheme://promoCode?parkingUid=${parkingUid}&promoId=${id}&promoCode=${promocode}`; // will result in error message if app not installed
    setTimeout(function() {
      // Link to the fallback url -- only fires if deep link fails
      window.location = currentLocation + "?appInstalled=no";
    }, 500);
  }

  useEffect(() => {
    if(promocode.trim()){
      axiosChangePromocodeStatus(id).then((res) => {
          console.log("Promo changed res: ", res)
      });
    }
    if (localStorage.getItem('inDate')) {
      localStorage.removeItem('inDate');
    }
    fetchParkingInfo();
    axiosBillingTokenFunc();
    return () => {};
  }, []);

  const handlePlateNumberChange = (event) => {
    let input_number = event.target.value;

    const letters_numbers = /^[a-zA-Z0-9]+$/;
    if (letters_numbers.test(input_number)) {
      setError({
        msg: "",
        type: ""
      });
      setAlertMessage("");
    } else {
      setError({
        msg: <div style={{textAlign: 'center', width: '100%'}} className={`error_msg ${isUae ? 'err-position' : ''}`}>Vehicle number can contain only latin letters or numbers</div>,
        type: ErrorType.USER_BASED
      });
    }

    setPlateNumber(event.target.value.toUpperCase());
    localStorage.setItem("plateNumber", event.target.value);
  };

  const handlePromocodeChange = (event) => {
    const symbols = /[`~!@#$%^&*()_=+?/]/;
    if (symbols.test(event.target.value)) {
      setError({
        msg: <div className={`error_msg ${isUae ? 'err-position' : ''}`}>Promo code can't contain any symbols except '-' </div>,
        type: ErrorType.USER_BASED
      });
    } else {
      setError({
        msg: "",
        type: ""
      });
    }

    setPromocode(event.target.value?.toUpperCase());
  };

  const handleContinue = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (promocode.trim()) {
      checkPromocode(event);
    } else {
      handleSubmit(event);
    }
  };

  const checkPromocode = (event) => {
    event.preventDefault();
    setIsLoading(true);
    let tempPromocode = promocode.toUpperCase();
    let array = [...promocode];
    if (array.find((v) => v === "-")) {
      let right = array.slice(array.findIndex((el) => el === "-") + 1);
      right = right.join("").toUpperCase();
      let left = array.slice(0, array.findIndex((el) => el === "-") + 1);
      left = left.join("");
      tempPromocode = left + right;
    }

    axiosSendPromocode(tempPromocode, plateNumber)
      .then((res) => {
        setIsLoading(false);
        if (res) {
          if (res?.status === 200) {
            toast.success(`${res?.data?.message}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/payment-success");
            }, 3000);
          }
        }
        if (res?.status === 208) {
          toast.error(`${res?.data?.message}`, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.status === 404 ||
          410 ||
          417 ||
          "unavailable" ||
          "NO INTERNET"
        ) {
          toast.error(`${err?.response?.data?.message}`, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {

          toast.error(`Apologies, an issue occurred. Please try again later.`, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }).finally(() => {
        setIsLoading(false)
    });
  };
  const fetchParkingInfo = async () => {
    await getParkingByUid(parkingUid).then(res => {
      setParkingInfo(res?.message)
    })
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsContinueLoading(true);
    console.log("Selected fields: ", uaeSelectedFields)
    if (paymentMethod === "stripe" || paymentMethod === "kaspi") {
      let finalPlateNumber;
      if (isUae) {
        // const regionCode = regionCodes[uaeSelectedFields.region];
        finalPlateNumber = reverse ? plateNumber + code : code + plateNumber;
      } else {
        finalPlateNumber = plateNumber;
      }
      stripePaymentCheck(finalPlateNumber, parkingUid, paymentMethod)
        .then((resp) => {
          if (resp?.status === 200 && resp?.data?.resultInt !== 1) {
            navigate(`/parking/${parkingUid}/info`, {
              state: {
                info: resp?.data.body,
                plateNumber: finalPlateNumber,
                parkingUid: parkingUid,
                paymentMethod: paymentMethod,
              },
            });
          }

          else if (resp?.data?.resultInt === 1) {
              if(parkingInfo.abonementsEnabled){
                navigate(`/parking/${parkingUid}/info`, {
                  state: {
                    info: {result: 'error', currency: resp.data?.body?.currency},
                    plateNumber: finalPlateNumber,
                    parkingUid: parkingUid,
                    paymentMethod: paymentMethod,
                  },
                });
              }else{
                setError({
                  msg: <div className={`error_msg ${isUae ? 'err-position' : ''}`}>{resp?.data?.message}</div>,
                  type: ErrorType.USER_BASED
                });
              }
          } else {
            setError({
              msg: (
                  <div className="error_msg" style={{textAlign: 'center'}}>
                    Apologies, an issue occurred.
                    <br/>
                    Try again later or pay at the payment terminal
                  </div>
              ),
              type: ErrorType.SERVICE_BASED
            });
          }
        })
          .catch(err => {
            console.log("ERROR: ", err)
            if(err?.response?.status === 500){
              setError({
                msg: (
                    <div className="error_msg" style={{textAlign: 'center'}}>
                      Apologies, an issue occurred.
                      <br/>
                      Try again later or pay at the payment terminal
                    </div>
                ),
                type: ErrorType.SERVICE_BASED
              });
            }else if (
                err?.status === 404 ||
                410 ||
                417 ||
                "unavailable" ||
                "NO INTERNET"
            ){
              setError({
                msg: <div className="error_msg" style={{textAlign: 'center'}}>{err?.response?.data?.message}</div>,
                type: ErrorType.SERVICE_BASED
              })
            } else {
              setError({
                msg: (
                    <div className="error_msg" style={{textAlign: 'center'}}>
                      Apologies, an issue occurred.
                      <br/>
                      Try again later or pay at the payment terminal
                    </div>
                ),
                type: ErrorType.SERVICE_BASED
              });
            }
          })
        .finally(() => setIsContinueLoading(false));
    } else {
      billingInfoFunc(
        localStorage.getItem("billingToken"),
        plateNumber,
        parkingUid,
        1
      )
        .then((resp) => {
          if (resp.code === 200 && resp.result.result !== 1) {
            navigate(`/parking/${parkingUid}/info`, {
              state: {
                info: resp.result,
                plateNumber: plateNumber,
                parkingUid: parkingUid,
                paymentMethod: paymentMethod,
              },
            });
          }
          else if (resp.result.message !== null) {
            setError({
              msg: <div className="error_msg" style={{textAlign: 'center'}}>{resp?.result?.message}</div>,
              type: ErrorType.SERVICE_BASED
            });
          } else {
            setError({
              msg: <div className="error_msg" style={{textAlign: 'center'}}>Please check the entered vehicle or if you are sure it is correct, contact parking support</div>,
              type: ErrorType.SERVICE_BASED
            });
          }
        })
        .catch((error) => {
          setError({
            msg: <div className="error_msg" style={{textAlign: 'center'}}>Please check the entered vehicle or if you are sure it is correct, contact parking support</div>,
            type: ErrorType.SERVICE_BASED
          });
        })
        .finally(() => setIsContinueLoading(false));
    }
  };
  const handleBlur = () => {
    if (plateNumber.length <= 4) {
      setError({
        msg: <div className={`error_msg ${isUae ? 'err-position' : ''}`}>{`Vehicle number must be greater then ${isUae ? '0' : '4'}`}</div>,
        type: ErrorType.USER_BASED
      });
    }
  };
  if(!parkingInfo){
    return (
        <div className={'middle'}><Loader/></div>
    )
  }

  return (
      <>
        <div className={'col center middle'}>
          <div className="container">
            <div className={'col'}>
              <div className={"welcome_div"}>
                <div>
                  <img src={logo}/>
                </div>

                <span>Welcome to the Parqour payment page.</span>
                <span>Please pay your parking fees!</span>
              </div>
              {alertMessage && (
                  <p className="message message_success">{alertMessage}</p>
              )}
              <form
                  onSubmit={handleContinue}
                  className="check-form"
              >

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <span style={{color: 'rgba(0, 0, 5, 0.95)', fontSize: '16px', fontWeight: '500'}}>
                    {parkingInfo.response ? '' : parkingInfo.name}
                  </span>
                    <span style={{color: '#666D80', fontSize: '12px', fontWeight: '500'}}>
                    PARKING
                  </span>
                </div>

                {
                  isUae
                      ? <UaeCarForm
                          plateNumber={plateNumber}
                          error={error} handlePlateNumber={handlePlateNumberChange}
                      />
                      : (
                          <>
                            <label className={"form-title"}>
                              <div className="input-wrapper">
                                <input
                                    style={{height: '56px'}}
                                    className={`${error.type === ErrorType.USER_BASED && "error"} ${
                                        plateNumber.length < 1 && "pt-0"
                                    }`}
                                    type="text"
                                    value={plateNumber}
                                    placeholder={"Enter vehicle number:"}
                                    onChange={handlePlateNumberChange}
                                    maxLength={10}
                                    onBlur={handleBlur}
                                />
                                {plateNumber.length > 0 && (
                                    <label htmlFor="input-field"
                                           className={`${error.type === ErrorType.USER_BASED && "red"}`}>
                                      Enter vehicle number:
                                    </label>
                                )}
                              </div>
                              {(error.type === ErrorType.USER_BASED && plateNumber.length > 0) &&
                                  error.msg}
                            </label>
                            {(paymentMethod === "portico" || paymentMethod === "stripe" || paymentMethod === "kaspi") &&
                                (parkingUid === "112" || parkingUid === "999" || parkingUid === "109") && (
                                    <div className="promocode-container"
                                         style={{marginBottom: '16px', alignItems: 'center'}}>
                                      <div className="promocode-container">
                                        <input
                                            type="text"
                                            placeholder={"XXX-ABCDE or ABCDE"}
                                            name="promocode"
                                            value={promocode}
                                            onChange={handlePromocodeChange}
                                        />
                                        {promocode?.length > 0 && (
                                            <label htmlFor="promocode">Promo code:</label>
                                        )}
                                      </div>
                                    </div>
                                )}
                          </>
                      )
                }

                <button
                    type="submit"
                    className={
                      `
                        ${error.msg ? 'btn-err' : ''}
                        ${plateNumber.length > (isUae ? 0 : 4) &&
                      plateNumber.length <= 10 &&
                      (!error.msg || error.type === ErrorType.SERVICE_BASED)
                          ? "bg-primary"
                          : "bg-secondary"}
                      `
                    }
                    disabled={
                        (plateNumber.length < (isUae ? 0 : 4) || plateNumber.length > 10) ||
                        (error.msg && error.type !== ErrorType.SERVICE_BASED)
                    }
                >
                  {isContinueLoading ? <LoadingSpinner/> : "Continue"}
                </button>

                {
                    error.type === ErrorType.SERVICE_BASED && (
                        error.msg
                    )
                }
                <div style={{textAlign: 'center', fontSize: '14px', color: '#666D80', marginTop: '8px'}}>
                  I have read and agree <br></br>
                  to the <a style={{color: '#157FFF', fontWeight: '400', textDecoration: 'none'}}
                            href={'https://parqour.kz/wp-content/uploads/2023/10/terms-of-use-en.pdf'}>terms of use the
                  site</a>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer/>

        </div>
        <div className={'all-rights-reserved'}>
          All rights reserved @2024
        </div>
      </>
  );
}

export default EnterCarForm;
