import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/mainLogo.png";
import { axiosLogin } from "../../axios/promocode";
import "./Login.css";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs'
function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    return () => {};
  }, []);

  const onChangeUsername = (event) => {
    let str = event.target.value;
    const letters_numbers = /^[a-zA-Z0-9]+$/;
    if (letters_numbers.test(str)) {
      setError("");
    } else {
      setError("Username can't contain cyrillic letters or any symbols");
    }
    setUsername(event.target.value);
  };

  const onChangePassword = (event) => {
    let str = event.target.value;
    const letters_numbers = /^[a-zA-Z0-9]+$/;
    if (letters_numbers.test(str)) {
      setError("");
    } else {
      setError("Password can't contain cyrillic letters or any symbols");
    }
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axiosLogin(username, password)
      .then((res) => {
        localStorage.setItem("accessToken", res?.accessToken);
        if (res.role === "OPERATOR") {
          navigate("/check-promocode");
        } else {
          navigate("/generate/promo-code");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={'middle'}>
      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={"welcome_div"}>
            <div>
              <img src={logo} alt="" />
            </div>
          </div>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="input-wrapper">
              <input
                type="text"
                value={username}
                placeholder={"Username"}
                onChange={onChangeUsername}
                style={{height: '56px'}}
              />
              {username.length > 0 && (
                <label htmlFor="input-field">Enter username:</label>
              )}
            </div>

            <div className="input-wrapper">
              <input
                type="password"
                value={password}
                placeholder={"Password"}
                onChange={onChangePassword}
                style={{height: '56px'}}

              />
              {password.length > 0 && (
                <label htmlFor="input-field">Enter password:</label>
              )}
            </div>

            <button
              type="submit"
              className={
                password.length > 1 && !error ? "bg-primary" : "bg-secondary"
              }
              disabled={loading}
            >
              {loading ? <p>Loading...</p> : <p>Sign in</p>}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
