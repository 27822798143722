import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  axiosBillingTokenFunc,
  axiosStripe,
  axiosM2pay,
  billingInfoFunc, axiosGetAbonementInfo, axiosAbonementCreate, getParkingByUid,
} from "../../axios/axiosRequest";
import Loader from "../../components/loader/Loader";
import logo from "../../assets/images/mainLogo.png";
import "./InfoCar.css";
import {DollarSvg} from "../../assets/images/DollarSvg";
import {SubscriptionSvg} from "../../assets/images/SubscriptionSvg";
import {PaymentInfo} from "./PaymentInfo";
import {SubscriptionInfo} from "./SubscriptionInfo";
import {currencySymbols} from "../../static/static";
import {isTablet} from "react-device-detect";
import {ParkingStatus} from "../../enums/responseStatus";
import {calculateStayDuration, formatCheckInDate} from "../../utils/helpers";
import {useSelector} from "react-redux";

function formatDateToYYYYMMDDHHmm(dateString) {
  if (dateString === "") return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function InfoCar({ qr }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [info, setInfo] = useState(location.state && location.state.info);
  // const [freeTimeBeforeExit, setFreeTimeBeforeExit] = useState(null);

  const [plateNumber, setPlateNumber] = useState(
      location.state && location.state.plateNumber
  );
  const [parkingUid, setParkingUid] = useState(
      (params.parkingUid) ||
      (location.state && location.state.parkingUid)
  );
  const [paymentMethod, setPaymentMethod] = useState(
      location.state && location.state.paymentMethod
  );
  const [error, setError] = useState("");
  const [payLoading, setPayLoading] = useState(false);
  const [amount, setAmount] = useState(info?.sum || 0);
  const [type, setType] = useState( info?.result !== 'error' ? params.rateType || 'parking' : 'subscription');
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState({
    loadingSubscription: false,
    loadingParkingInfo: false
  })
  const [subscriptions, setSubscriptions] = useState([])
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [innerParkings, setInnerParkings] = useState([])
  const [innerParkingId, setInnerParkingId] = useState(null);
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [parkingInfo, setParkingInfo] = useState({
    currency: '$',
    abonementEnabled: true
  })
  const { region, code, reverse } = useSelector((state) => state.uaeCarForm);

  const [selectedSubscription, setSelectedSubscription] = useState({
    id: -999,
    name: 'fake',
    value: '$80',
  },)
  localStorage.setItem("parkingUid", params.parkingUid);
  localStorage.setItem("paymentMethod", paymentMethod);

  // useEffect(() => {
  //   if (info?.leftFreeTimeMinutes != null) {
  //     // const calculatedTime = new Date(new Date().getTime() + info.leftFreeTimeMinutes * 60000 + 60000);
  //     const calculatedTime = new Date(new Date(info.inDate).getTime() + (10 * 60000)).toISOString();
  //     setFreeTimeBeforeExit(calculatedTime);
  //   }
  // }, [info?.leftFreeTimeMinutes]);

  const fetchSubscriptions = async (retryCount = 0) => {
    try {
      setLoader(prevState => ({
        ...prevState,
        loadingSubscription: true
      }));

      const response = await axiosGetAbonementInfo(parkingUid, 'en');
      if(response?.message !== 'error'){

        setSubscriptions(response?.message.abonementTypes);
        setInnerParkings(response?.message.innerParkings);
        setSelectedSubscription(response?.message.abonementTypes[0]);
        setInnerParkingId(response?.message.innerParkings[0].id);
        setLoader(prevState => ({
          ...prevState,
          loadingSubscription: false
        }));

      }
    } catch (err) {
      console.log("Error: ", err)
      if (err.response && err.response.status === 404) {
        console.error("404 Not Found: The requested resource was not found.");
        if (retryCount < 5) { // retry limit up to 5
          console.log("Retry count: ", retryCount)
          fetchSubscriptions(retryCount + 1);
        } else {
          console.error("Retry limit reached, stopping further attempts.");
        }
      } else {
        console.error("An error occurred: ", err.message);
      }
    }
  };

  const fetchParkingInfo = async () => {
    setLoader(prevState => ({
      ...prevState,
      loadingParkingInfo: true
    }));


    await getParkingByUid(parkingUid).then(res => {
      setParkingInfo({
        isEnabled: res?.message.abonementsEnabled,
        currency: res?.message.currency,
        name: res?.message.name,
      });
      setLoader(prevState => ({
        ...prevState,
        loadingParkingInfo: false
      }));

    })
  }
  useEffect(() => {
    fetchSubscriptions();
    fetchParkingInfo();
    if(localStorage.getItem('isUae') && info.result === ParkingStatus.SUCCESS){
      localStorage.setItem('inDate', info.inDate);
      // const differenceInMinutes = Math.abs(new Date() - new Date(info.inDate)) / (1000 * 60);
      // localStorage.setItem('duration', info.hours === 0 ? {type: "min", val: differenceInMinutes} : {type: "h", val: info.hours})

    }

    if (qr) {
      setPlateNumber(params.plateNumber);
      setParkingUid(params.parkingUid);
      setPaymentMethod(params.paymentMethod);
      localStorage.setItem("paymentMethod", params.paymentMethod);

      axiosBillingTokenFunc();
      billingInfoFunc(
          localStorage.getItem("billingToken"),
          params.plateNumber,
          params.parkingUid,
          1
      ).then((resp) => {
        console.log(resp);
        setInfo(resp.result);
      });
    }
  }, []);
  useEffect(() => {
    if(type === 'subscription'){
      localStorage.setItem('subscription', 'true');
    }else{
      localStorage.removeItem('subscription');
    }
  }, [type]);
  const now = new Date();

  // Get the target date and time
  const targetDate = info && new Date(info.in_date);

  // Calculate the duration in milliseconds
  const duration = info && targetDate.getTime() - now.getTime();

  // Convert the duration to minutes
  const durationInMinutes = info && Math.floor(duration / 1000 / 60);
  const hours = Math.floor(Math.abs(durationInMinutes) / 60);
  const minutes = Math.abs(durationInMinutes) % 60;

  const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

  const handlePayment = () => {
    if (paymentMethod === "portico") {
      localStorage.setItem("sum", info.sum);
      localStorage.setItem("plateNumber", plateNumber);
      localStorage.setItem("currency", info?.currency);

      navigate(`/parking/${parkingUid}/payment`, {
        state: {
          paymentInfo: {
            sum: info.sum,
            plateNumber: plateNumber,
            currency: info?.currency,
          },
        },
      });
    } else if (paymentMethod === "stripe") {
      setPayLoading(true);
      axiosStripe(
          plateNumber,
          amount,
          info?.currency,
          window.location.origin + `/payment-success?parkingUid=${parkingUid}`,
          window.location.origin + "/payment-error",
          parkingUid
      )
          .then((resp) => {
            window.open(resp.data?.url, "_self");
          })
          .finally(() => setPayLoading(false));
    } else if (paymentMethod === "m2pay") {
      setPayLoading(true);
      axiosM2pay(
          plateNumber,
          info.sum,
          window.location.origin + "/payment-success",
          window.location.origin + "/payment-error",
          parkingUid
      )
          .then((res) => {
            console.log(res);
          })
          .finally(() => setPayLoading(false));
    } else if (paymentMethod === "kaspi") {
      window.location.href = 'https://kaspi.kz/pay/VBRParking';
    }
  };

  const handleSubscriptionPayment = () => {
    const dateObject = new Date(date);
    const formattedDate = formatDate(dateObject);
    setPayLoading(true);
    axiosAbonementCreate(plateNumber, parkingUid, innerParkingId, selectedSubscription.id, formattedDate).then(res => {
      const amountToPay = info.result === 'error' ? selectedSubscription.price : subscriptionAmount
      if(selectedSubscription.price > info?.currentBalance || info.result === 'error'){
        axiosStripe(
            plateNumber,
            amountToPay,
            info?.currency,
            window.location.origin + `/payment-success?parkingUid=${parkingUid}`,
            window.location.origin + "/payment-error",
            parkingUid,
            parkingUid === "118" || parkingUid === "129"
        ).then(stripeRes => {
          window.open(stripeRes.data?.url, "_self");
        })
            .finally(() => setPayLoading(false));
      }else{
        navigate('/payment-success');
        setPayLoading(false);
      }


    }).catch(err => {
      setPayLoading(false);
      alert(`${err.message} ${err.code}`)
    })
  }

  const handleImitation = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/payment-success");
    }, 1500);
  };

  return (
      <div style={{height: type !== 'parking' && !isTablet && localStorage.getItem('region') !== 'Saudi Arabia' ? '' : '100vh'}} className={'col center'}>
        <div className="infoCar_container">
          {
            loader.loadingParkingInfo || (parkingInfo.isEnabled && loader.loadingSubscription) ? <div className={'middle'}><Loader/></div>
                : (
                    <>

                      {
                        parkingInfo.isEnabled && (!subscriptions || subscriptions.length !== 0) ? (
                            <>
                              {
                                !info ? <div className={'middle'}><Loader/></div> : (
                                    <>
                                      <div className={"welcome_div"}>
                                        <div>
                                          <img src={logo}/>
                                        </div>
                                        <span>Parking session details</span>
                                      </div>
                                      <div
                                          style={{
                                            height: "100%",
                                            justifyContent: "unset",
                                            padding: "20px 15px",
                                          }}
                                          className={"check-form"}
                                      >
                                        <div className={'paymentContainer'}>
                                          {
                                              info.result !== 'error' &&
                                              <PaymentOption type={type} setType={setType} optionType='parking'
                                                             SvgComponent={DollarSvg}/>
                                          }
                                          <PaymentOption type={type} setType={setType} optionType='subscription'
                                                         SvgComponent={SubscriptionSvg}/>
                                        </div>
                                        {!info || loading ? (
                                            <div style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                                              <Loader/>
                                            </div>
                                        ) : type === 'parking' ? (
                                            <PaymentInfo
                                                info={info}
                                                parkingUid={parkingUid}
                                                plateNumber={plateNumber}
                                            />
                                        ) : (
                                            <SubscriptionInfo
                                                subscriptions={subscriptions}
                                                info={info}
                                                plateNumber={plateNumber}
                                                selectedSubscription={selectedSubscription}
                                                setSelectedSubscription={setSelectedSubscription}
                                                setDate={setDate}
                                                setInnerParkingId={setInnerParkingId}
                                                innerParkings={innerParkings}
                                                setSubscriptionAmount={setSubscriptionAmount}
                                                parkingUid={parkingUid}
                                            />
                                        )}
                                        {
                                          info.result !== ParkingStatus.SUCCESS && type === 'parking' ?
                                              (
                                                  <></>
                                              ) :
                                              (
                                                  <button
                                                      disabled={
                                                          (type === 'subscription' && selectedSubscription.name === 'fake') ||
                                                          (info.sum === 0 && type !== 'subscription') || region === 'Saudi Arabia'
                                                      }
                                                      onClick={info.sum || selectedSubscription.price ? type === 'subscription' ? handleSubscriptionPayment : handlePayment : handleImitation}
                                                      className=
                                                          {
                                                            `infoCar_button                                              
                                                              ${type === 'subscription' && selectedSubscription.name === 'fake' ? 'bg-secondary' : 'bg-primary'}
                                                              ${info.sum === 0 && type !== 'subscription' ? 'bg-secondary' : ''}
                                                              ${region === 'Saudi Arabia' && info.result !== ParkingStatus.SUCCESS && type !== 'parking' ? 'bg-secondary' : 'bg-primary'}
                                                             `
                                                          }
                                                  >
                                                    {payLoading ? <p>Loading...</p> : <p>Pay</p>}
                                                  </button>
                                              )
                                        }
                                      </div>
                                    </>
                                )
                              }
                            </>
                        ) : (
                            <>
                              <div className={"welcome_div"}>
                                <div>
                                  <img src={logo}/>
                                </div>
                                <span>Parking session details</span>
                              </div>
                              <div
                                  style={{
                                    height: info.sum > 0 ? "490px" : "350px",
                                    justifyContent: "unset",
                                    padding: "20px 15px",
                                  }}
                                  className={"check-form"}
                              >
                                {!info || loading ? (
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                      <Loader/>
                                    </div>
                                ) : (
                                    <>
                                      <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                      >
                                        <span className="infoCar_name" style={{marginBottom: "8px"}}>
                                          {parkingInfo?.name}
                                        </span>
                                      </div>
                                      <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                      >
                                        <div className="infoCar_info">
                                          <span>Current Balance: </span>
                                          <span>
                                            {info?.currentBalance?.toLocaleString("en").replace(/,/g, " ")
                                                || info.current_balance}{" "} {currencySymbols[info?.currency?.toUpperCase()]}
                                          </span>
                                        </div>
                                        <div className="infoCar_info">
                                          <span>License plate: </span>
                                          <span>{plateNumber}</span>
                                        </div>
                                        {
                                            info?.inDate && (
                                                <div className="infoCar_info">
                                                  <span>Entrance time: </span>
                                                  <span>{formatCheckInDate(info.inDate)}</span>
                                                </div>
                                            )
                                        }
                                        <div className="infoCar_info">
                                          <span>Parking duration: </span>
                                          <span>
                                            {info?.durationInMinutes ? (
                                                info.in_date === ""
                                                    ? "No entries"
                                                    : Math.abs(info.durationInMinutes) < 60
                                                        ? Math.abs(info.durationInMinutes) + " minutes"
                                                        : Math.floor(Math.abs(info.durationInMinutes) / 60) +
                                                        ` ${info.durationInMinutes > 1 ? "hours" : "hour"} and ` +
                                                        (Math.abs(info.durationInMinutes) % 60) +
                                                        " minutes"
                                            ) : calculateStayDuration(info.inDate)}
                                          </span>
                                        </div>
                                        <div className="infoCar_info highlighted">
                                          <span className="highlighted">Parking fee: </span>
                                          <span>
                                            {info.sum?.toLocaleString("en").replace(/,/g, " ")}{" "}
                                            {currencySymbols[info?.currency?.toUpperCase()]}
                                          </span>
                                        </div>

                                        {
                                            info.result === ParkingStatus.SUCCESS &&
                                            (
                                                <>
                                                  <span className="infoCar_additional_info">
                                                    To replenish the balance, enter the payment amount
                                                  </span>
                                                  <button
                                                      style={{marginTop: '0'}}
                                                      onClick={info.sum ? handlePayment : handleImitation}
                                                      className={`infoCar_button bg-primary`}
                                                  >
                                                    {payLoading ? <p>Loading...</p> : <p>Pay</p>}
                                                  </button>
                                                </>
                                            )
                                        }

                                      </div>
                                    </>
                                )}
                              </div>

                            </>
                        )
                      }
                    </>
                )
          }
        </div>
      </div>

  );
}

export default InfoCar;

const PaymentOption = ({type, setType, optionType, SvgComponent}) => {
  const isActive = type === optionType;
  const color = isActive ? '#FFF' : '#157FFF';

  return (
      <div className={`payment-type ${isActive && 'active'}`} onClick={() => setType(optionType)}>
        <SvgComponent color={color}/>
        {optionType === 'parking' ? 'Pay for parking' : 'Subscription'}
      </div>
  );
};

function formatDate(date) {
  const year = date.getFullYear();
  // Adding 1 to month since getMonth() returns 0-11
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hour}:${minute}`;
}
