import React from 'react';
import notFound from '../../assets/images/nopromo.svg'

const EmptyHistory = ({title, description, clickHandler, buttonClassName, buttonName }) => {
    return (
        <div className="company-no-history">
            <div className="col center">
                <img src={notFound || ''} alt="Not Found" />
                <div className="col center">
                    <span className="text-loud">{title}</span>
                    <span className="subdued-text">{description}</span>
                </div>
                <button
                    className={buttonClassName}
                    onClick={clickHandler}
                    style={{ fontSize: '14px', marginTop: '5px' }}
                >
                    {buttonName}
                </button>
            </div>
        </div>
    );
};
//"cus-button text-content"

export default EmptyHistory;
